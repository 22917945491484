@use '../../0-settings/settings' as s;
@use './text-input' as input;
@use '../common/_icon';
@use "sass:math";

// Wrapper component used for positioning icons inside an input (e.g. search icon within search field)

.c-input-container {
  position: relative;
}

.c-input-container--tiny {
  max-width: s.$input-width--tiny;
}

.c-input-container--small {
  max-width: s.$input-width--small;
}

.c-input-container--medium {
  max-width: s.$input-width--medium;
}

.c-input-container--large {
  max-width: s.$input-width--large;
}

.c-input-container--default {
  max-width: s.$input-width--default;
}

.c-input-container__icon {
  position: absolute;
  left: input.$field-padding--horizontal;
  top: 50%;
  z-index: 10;
  transform: translateY(math.div(icon.$icon-size--default, 2) * -1);
}

.c-input-container__icon-btn {
  // Position
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 10;

  // Layout
  display: flex;
  justify-content: center;
  align-items: center;

  // Dimensions
  height: calc(100% - 4px);
  padding: 0 s.$size--200;
  border-radius: 2px;
  // Theming
  border: none;
  background-color: white;
  cursor: pointer;

  .c-icon {
    width: 18px;
    height: 18px;
  }
  &--hidden{
    opacity:0;  
    &:focus{opacity:1}
  }
}
