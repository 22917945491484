/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

button {
    display: inline-block;
    border: none;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
}


//todo this needs refactering!

%btn {
    @extend %remove-anchor-decoration;
    @extend %text-center;
    @extend %inline-block;
    @extend %border-box;
    @extend %mt-0;
    @extend %mb-xxl;
    @extend %mb-0;
    @extend %pl-l;
    @extend %pr-l;
    @extend %pb-s;
    @extend %pt-s;

    white-space: normal;
    border: none;
    cursor:pointer;
    font-family: inherit;
    color: inherit;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: inherit;

    &:hover {
        text-decoration: none;
    }

    & + %button {
        @include rem(margin-top, $space-m);
    }
}

%btn--soft {
    @include rem(border-radius, $space-xxs);
}

%btn--block {
    @extend %first-child-no-top;
    @extend %mt-m;

    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

%btn-primary {
    background-color: $base-link-color;
    color: $white;
    border: $base-link-color;
    @extend %font-weight-bold;

    &:visited {
        color: $white;
    }

    &:hover {
        background: darken($base-link-color, 10%);
        color: $white;
    }

    &:active {
        color:$white;
        background: $base-link-color;
    }
}

%btn-secondary {
    @extend %font-weight-bold;

    background: $white;
    color: $base-link-color;
    box-shadow: inset 1px 0 0 $base-link-color, inset 0 1px 0 $base-link-color, inset -1px 0 0 $base-link-color, inset 0 -1px 0 $base-link-color;

    &:visited {
        color: $base-link-color;
    }

    &:hover {
        background-color: #F2F4F7;
        color: $base-link-color;
    }

    &:active {
        background-color: white;
        box-shadow: inset 1px 0 0 darken($base-link-color,10%), inset 0 1px 0 darken($base-link-color,10%), inset -1px 0 0 darken($base-link-color,10%), inset 0 -1px 0 darken($base-link-color,10%);
        color: darken($base-link-color, 10%);
    }

    &.btn-nav {
        color: $base-link-color;
    }
}

%btn-tertiary {
    @extend %font-size-xs;

    background: none;
    color: $base-link-color;
    box-shadow: none;

    &:visited {
        color: $base-link-color;
    }
}



// buttons
.btn {
    @extend %btn;
    @extend %btn--soft;
}

.btn--block {
    @extend %btn--block;
}

.btn-nav {
    margin: 11px 10px 11px;
}

/**
 * Make a button behave like a block at mobile only (also handy)
 */
@include media(small-down) {
    .btn--block-mobile {
        text-align: center;
        display: block;
    }

    .btn-nav {
        font-size: .8rem;
        margin: 0px;
    }
}

/**
### Buttons in forms
Try and avoid using `<input type='submit' value='Go'>` in favour of
`<button type='submit'>Go</button>` as it's easier to style.
*/

%btn--submit {
    @extend %btn;
    @extend %btn--block;
    @extend %btn--soft;
    @extend %btn-primary;
    @extend %cursor-on-hover;
}

.btn--submit {
    @extend %btn--submit;
}

.btn--primary {
    @extend %btn-primary;
}

.btn--secondary {
    @extend %btn-secondary;
}

.btn--tertiary {
    @extend %btn-tertiary;
}

.btn + .btn {
    @extend %ml-l;
}
.btn--block + .btn--block {
    margin-left:0;
}

%btn--bounce {
    position: relative;
    top: 0;

    &:hover {
        top: .1em;
    }
}

.btn--bounce {
    @extend %btn--bounce;
}
