.webcam {
    line-height: 0;
    position: relative;
}

    .webcam--detail {
        & > .webcam__caption {
        }
    }

    .webcam__image {
        line-height: 1;
        width: 100%;
        height: auto;
    }
    .webcam__caption {
        @include font-size(14);
        @extend %font-weight-bold;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @include rem(padding-top, 16);
        @include rem(padding-bottom, 12);
        @include rem(padding-left, 32);
        @include rem(padding-right, 32);
        color: #fff;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
    }
    .webcam__last-update {
        & > .i {
            @include font-size(16);
            @include rem(margin-top, -1);
            @extend %mr-xxs;
        }
        display: block;
        @extend %font-weight-normal;
    }



$width-webcam-image: 78;
$height-webcam-image: 64;

.webcam-list {
    padding-top: 0;
    @extend %theme--dark;
    @extend %inline-block;
    @extend %list-unstyled;
    white-space: nowrap;
    overflow: hidden;
    @include vendor(border-radius, 5px);
    margin: 0;

    & > li {
        @extend %inline-block;
        margin-right: -.2em;
        vertical-align: top;
        margin-top: 0;
        @include rem(width, $width-webcam-image);
        overflow: hidden;
        white-space: normal;
    }

    .webcam {
        display: block;
        position: static;
        & > .webcam__image {
            @include rem(height, $height-webcam-image);
            width: auto;
        }
        & > .webcam__caption {
            position: static;
            line-height: 1.2;
            padding: .2em .3em .3em;
            background: none;
        }
    }
}
