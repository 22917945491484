@use '../0-settings/settings' as s;
@use '../1-mixins/breakpoints';

.l-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.l-content__wrapper {
  overflow: auto;
  padding: s.$size--500;
  flex: 1;
  height: 100%;
  @include breakpoints.ipad-portrait-up {
    padding: s.$size--650;
    margin-bottom: 0;
  }
}
