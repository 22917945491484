/**
*  ================
*   BOX-SIZING
*  ================
*
*  Set the global `box-sizing` state to `border-box`. Note I don't use the
*  updated version linked to below as I don't like children inheriting
*  `box-sizing: content-box` from the parent when I use that on content
*  containers etc. Happy to discuss if others feel strongly :)
*
*  css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
*  paulirish.com/2012/box-sizing-border-box-ftw
*/

*,
*:before,
*:after {
  box-sizing: border-box;
}
