/*------------------------------------*\
  Additional colour variables needed:
  - Prefixed with ms to avoid clashes
    with pattern library variables...
\*------------------------------------*/

$ms-blue: #00a8ff;
$ms-blue-dark: #1d6ea8;
$ms-blue-extra-dark: #005485;
$ms-green-light: #afc83d;
$ms-green-extra-light: #f7f9e9;
$ms-green: #59b357;
$ms-red: #db0000;
$ms-yellow: #ff9600;
$ms-grey-light: #f2f2ee;
$ms-grey-mid-light: #d3d3d3;
$ms-medium-red: #cc4444;
