@use '../../0-settings/settings' as s;

.c-info-list {
  list-style-type: none;
  padding-left: 0;
  padding-top: s.$size--100;
}

.c-info-list__item {
  display: flex;
  margin: s.$size--200 0;
  p {
    margin: 0;
  }
}

.c-info-list__label {
  font-weight: s.$weight--bold;
  flex: 1 1 0px;
}

.c-info-list__data {
  flex: 1 1 0px;
}

.c-info-list__data-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}


.condition{
  display:inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
  &--green{
    background-color: green
  }
  &--amber{
    background-color: orange
  }
}