@use '../../0-settings/settings' as s;
@use '../../1-mixins/_breakpoints';
@use 'sass:math';

// Note: styles modal generated by React Modal library
// See https://reactcommunity.org/react-modal/

// Private vars
$_content-padding: s.$size--600;

.c-modal {
  // Layout
  display: flex;
  flex-direction: column;

  // Dimensions
  width: 100%;
  height: 100%;
  padding: $_content-padding;

  // Theming
  background-color: s.$color--white;

  @include breakpoints.tablet-portrait-up {
    margin: s.$size--300;
    max-width: s.$size--1500;
    height: auto;
    box-shadow: 0px 0px 30px -5px rgba(68, 51, 122, 0.3);
    border-radius: s.$border-radius--default;
  }

  &:focus-visible {
    outline: none;
  }
}

.c-modal__overlay {
  // Layout
  display: flex;
  justify-content: center;
  align-items: center;

  // Positioning
  position: fixed;
  top: 0;
  left: 0;

  // Dimensions
  width: 100%;
  height: 100%;
  z-index: s.$z--modals;

  // Theming
  background-color: rgba(243, 244, 250, 0.9);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity s.$speed--ease-slow s.$easing--default;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  position: relative;
}

// Boilerplate option styles (component has
.c-modal__heading {
  margin-top: 0;
  font-size: s.$font-size--500;
  padding-right: $_content-padding;
  margin-bottom: s.$size--200;
}

.c-modal__body-text {
  margin-bottom: s.$size--700;
  line-height: s.$line-height--large;
}

.c-modal__close-button {
  position: absolute;
  right: math.div($_content-padding, 2);
  top: math.div($_content-padding, 2);
}
