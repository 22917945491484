@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-pop-out-panel {
  position: fixed;
  box-shadow: s.$shadow--200;
  background: s.$color--gray-wash;
  flex: 1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: s.$z--pop-out-panel--mobile;
  overflow: auto;

  @include breakpoints.tablet-portrait-up {
    position: absolute;
    left: s.$side-panel-width;
    width: s.$pop-out-panel-width;
    height: 100%;
    z-index: s.$z--pop-out-panel;
  }
}

// For EV chargers only - modify infolist
.c-pop-out-panel__ev {
  .c-info-list__label {
    flex: 100px 0 0;
  }
  .c-info-list__data {
    flex: 1 1 auto;
  }
}

.c-pop-out-panel__heading {
  margin-bottom: 0;
  padding-right: s.$size--675;
  outline: unset;
}

.c-pop-out-panel__footer {
  display: flex;
  justify-content: space-between;
  margin-top: s.$size--650;
}

.c-pop-out-panel__header {
  position: relative;
  padding-top: s.$size--600;
  padding-left: s.$size--450;
  padding-right: s.$size--450;

  @include breakpoints.tablet-portrait-up {
    padding-top: s.$size--450;
  }
}

.c-pop-out-panel__header .c-btn--icon {
  position: absolute;
  right: 14px;
  top: 14px;
}
