/*------------------------------------*\
    #THEMING
\*------------------------------------*/

$themes:
    grey #D8D8D8 $icon-bg-dark-grey #F2F4F7 #095685 $icon-bg-dark-blue,
    dark-grey #555555 $icon-bg-dark-grey #555555 #ffffff $icon-bg-white,
    dark-blue $color-bg-header $icon-bg-dark-grey $color-bg-header #ffffff $icon-bg-white,
    confirm $confirmation-green $icon-bg-green $confirmation-green #ffffff $icon-bg-white,
    orange #452606 $icon-bg-dark-grey $color-orange #452606 $icon-bg-dark-grey,
    yellow #FFD633 $icon-bg-dark-grey #FFD633 #452606 $icon-bg-dark-grey,
    warning $warning-red $icon-bg-red $warning-red #fff $icon-bg-white,
    closures #fff $icon-bg-white $black #fff $icon-bg-white,
    twitter #55ACEE $icon-bg-dark-grey #55ACEE #fff $icon-bg-white,
    facebook #3B5998 $icon-bg-dark-grey #3B5998 #fff $icon-bg-white,
    area-warning #452606 $icon-bg-dark-grey #f1561c #fff $icon-bg-dark-grey;


@each $theme in $themes {

    $theme-name: save-nth-list-value($theme, 1);
    $theme-color-text: save-nth-list-value($theme, 2);
    $theme-position-icon: save-nth-list-value($theme, 3);
    $theme-color-bg: save-nth-list-value($theme, 4);
    $theme-color-bg-text: save-nth-list-value($theme, 5);
    $theme-position-bg-icon: save-nth-list-value($theme, 6);

    #{'.block-theme--'+$theme-name} {

        color: $theme-color-text;
        & .i {
            background-position: 0 $theme-position-bg-icon;
        }

        border-width: 0 !important;

        .theme__background {
            @if($theme-color-bg != null) {
                background-color: $theme-color-bg;
//                &.-hover:hover,
//                & .-hover:hover {
//                    background-color: darken($theme-color-bg, 8%);
//                }
            }
            @if($theme-color-bg-text != null) {
                color: $theme-color-bg-text;
                @if (lightness($theme-color-bg-text) > 50 and lightness($theme-color-bg) < 60) {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
            @if($theme-position-bg-icon != null) {
                & .i {
                    background-position: 0 $theme-position-bg-icon;
                }
            }
        }
        a.theme__background {
            @if($theme-color-bg != null) {
                @include hover(background-color, darken($theme-color-bg, 8%));
            }
        }

    }

    #{'%inline-theme--'+$theme-name} {

        @if($theme-color-bg-text != null) {
            color: $theme-color-bg-text;
        }

        @if($theme-color-bg != null) {
            background-color: $theme-color-bg;
            &.-hover:hover,
            & .-hover:hover {
                background-color: darken($theme-color-bg, 8%);
            }
        }
        @if($theme-color-bg-text != null) {
            color: $theme-color-bg-text;
            @if (lightness($theme-color-bg-text) > 50 and lightness($theme-color-bg) < 60) {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        @if($theme-position-bg-icon != null) {
            & i {
                background-position: 0 $theme-position-bg-icon !important;
            }
        }

    }
    #{'.inline-theme--'+$theme-name} {
        @extend #{'%inline-theme--'+$theme-name};
    }

    #{'%link-theme--'+$theme-name} {

        @if($theme-color-bg != null) {
            color: $theme-color-bg-text;
            background-color: $theme-color-bg;
            &:hover {
                background-color: darken($theme-color-bg, 8%);
            }
        }

    }
    #{'.link-theme--'+$theme-name} {
        @extend #{'%link-theme--'+$theme-name};
    }

}


// google theme is same as warning theme
.block-theme--google {
    @extend .block-theme--warning;
}
%inline-theme--google {
    @extend %inline-theme--warning;
}
.inline-theme--google {
    @extend %inline-theme--google;
}
%link-theme--google {
    @extend %link-theme--warning;
}
.link-theme--google {
    @extend %link-theme--google;
}

