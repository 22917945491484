@use '../0-settings/settings' as s;
@use '../1-mixins/breakpoints';
@use '../5-components/navigation/header';

.l-page__content .l-banners {
  flex: 0 0 auto;
}

.l-banners {
  flex: 0 0 auto;
}
