@use '../../0-settings/settings' as s;

.lightswitch__rhs{
    display:flex;
    align-items: center;
    justify-content: space-between;
    h4{
        margin-bottom: 0;
    }
      
  }
  
  .lightswitch{
      flex:1;
      display:flex;
      align-items: center;
      justify-content: flex-end;
      span{
          font-size: 14px;
          font-weight: bold;
          margin-right: 11px;
      }
      input[type=checkbox]{
          height: 0;
          width: 0;
          visibility: hidden;
          }
      
          label {
              cursor: pointer;
              text-indent: -9999px;
              width: 26px;
              height: 14px;
              background: s.$color--silver-chalice;
              display: block;
              border-radius: 13px;
              position: relative;
          }
      
          label:after {
              content: '';
              position: absolute;
              top: 2px;
              left: 2px;
              width: 10px;
              height: 10px;
              background: #fff;
              border-radius: 10px;
              transition: 0.3s;
          }
      
          input:checked + label {
              background: s.$color--success;
          }
      
          input:checked + label:after {
              left: calc(100% - 2px);
              transform: translateX(-100%);
          }
      
          label:active:after {
              width: 26px;
          } 
  }