/**
*  ================
*   CONTAINERS
*  ================
*
*  Responsive containers used to create horizontally centered, consistently
*  sized max-width wrapper elements.
*
*  ### ARGUMENTS ###
*  $max-width: maximum width container can grow to on larger screens
*  $gutter: amount of padding left and right of container on smaller screeens
*
*  ### USAGE: ###
*  .page-content {
*    @include container();
*  }
*
*  .modal {
*    @include container(20px, 600px);
*  }
*
*/

@use '../0-settings/settings';

@mixin container($gutter-width: settings.$default-gutter-width, $max-width: settings.$container-max-width) {
  width: 100%;
  max-width: $max-width;
  padding-left: $gutter-width;
  padding-right: $gutter-width;
  margin-left: auto;
  margin-right: auto;
}
