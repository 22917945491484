/*
 * See http://flexboxgrid.com/ for usage
 */
 $size--10: 1px;
 $size--25: 2px;
 $size--50: 4px;
 $size--100: 8px; // base size
 $size--150: 12px;
 $size--175: 14px;
 $size--200: 16px;
 $size--250: 20px;
 $size--300: 24px;
 $size--350: 26px;
 $size--400: 32px;
 $size--450: 36px;
 $size--500: 40px;
 $size--600: 48px;
 $size--650: 52px;
 $size--700: 56px;
 $size--800: 64px;
 $size--900: 72px;
 $size--1000: 80px;
 $size--1200: 96px;
 $size--1400: 160px;
 
// Gutters　
$gutter-width: $size--300;
$outer-margin: $size--300;
$gutter-compensation: calc((#{$gutter-width} * 0.5) * -1);
$half-gutter-width: calc(#{$gutter-width} * 0.5);

// Breakpoints
$sm-min: 768px;
$md-min: 1024px;
$lg-min: 1280px;

// Vertical spacing
$grid--vert-gap-sm: $size--300;
$grid--vert-gap-lg: $size--600;


.l-grid {
  margin-right: auto;
  margin-left: auto;
}

.l-grid--vert-gap-sm {

  [class^="l-grid__col"] {
    margin-bottom: $grid--vert-gap-sm;
  }

}

.l-grid--vert-gap-lg {

  [class^="l-grid__col"] {
    margin-bottom: $grid--vert-gap-lg;
  }

}

.l-grid__row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

.l-grid__row.reverse {
  flex-direction: row-reverse;
}

.l-grid__col.reverse {
  flex-direction: column-reverse;
}

.l-grid__col-xs,
.l-grid__col-xs-1,
.l-grid__col-xs-2,
.l-grid__col-xs-3,
.l-grid__col-xs-4,
.l-grid__col-xs-5,
.l-grid__col-xs-6,
.l-grid__col-xs-7,
.l-grid__col-xs-8,
.l-grid__col-xs-9,
.l-grid__col-xs-10,
.l-grid__col-xs-11,
.l-grid__col-xs-12,
.l-grid__col-xs-offset-0,
.l-grid__col-xs-offset-1,
.l-grid__col-xs-offset-2,
.l-grid__col-xs-offset-3,
.l-grid__col-xs-offset-4,
.l-grid__col-xs-offset-5,
.l-grid__col-xs-offset-6,
.l-grid__col-xs-offset-7,
.l-grid__col-xs-offset-8,
.l-grid__col-xs-offset-9,
.l-grid__col-xs-offset-10,
.l-grid__col-xs-offset-11,
.l-grid__col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

.l-grid__col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.l-grid__col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.l-grid__col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.l-grid__col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.l-grid__col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.l-grid__col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.l-grid__col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.l-grid__col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.l-grid__col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.l-grid__col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.l-grid__col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.l-grid__col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.l-grid__col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.l-grid__col-xs-offset-0 {
  margin-left: 0;
}

.l-grid__col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.l-grid__col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.l-grid__col-xs-offset-3 {
  margin-left: 25%;
}

.l-grid__col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.l-grid__col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.l-grid__col-xs-offset-6 {
  margin-left: 50%;
}

.l-grid__col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.l-grid__col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.l-grid__col-xs-offset-9 {
  margin-left: 75%;
}

.l-grid__col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.l-grid__col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

.hide-xs{
  display:none !important;
}

@media only screen and (min-width: $sm-min) {

  .l-grid__col-sm,
  .l-grid__col-sm-1,
  .l-grid__col-sm-2,
  .l-grid__col-sm-3,
  .l-grid__col-sm-4,
  .l-grid__col-sm-5,
  .l-grid__col-sm-6,
  .l-grid__col-sm-7,
  .l-grid__col-sm-8,
  .l-grid__col-sm-9,
  .l-grid__col-sm-10,
  .l-grid__col-sm-11,
  .l-grid__col-sm-12,
  .l-grid__col-sm-offset-0,
  .l-grid__col-sm-offset-1,
  .l-grid__col-sm-offset-2,
  .l-grid__col-sm-offset-3,
  .l-grid__col-sm-offset-4,
  .l-grid__col-sm-offset-5,
  .l-grid__col-sm-offset-6,
  .l-grid__col-sm-offset-7,
  .l-grid__col-sm-offset-8,
  .l-grid__col-sm-offset-9,
  .l-grid__col-sm-offset-10,
  .l-grid__col-sm-offset-11,
  .l-grid__col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
  }

  .l-grid__col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .l-grid__col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .l-grid__col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .l-grid__col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .l-grid__col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .l-grid__col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .l-grid__col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .l-grid__col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .l-grid__col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .l-grid__col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .l-grid__col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .l-grid__col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .l-grid__col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .l-grid__col-sm-offset-0 {
    margin-left: 0;
  }

  .l-grid__col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .l-grid__col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .l-grid__col-sm-offset-3 {
    margin-left: 25%;
  }

  .l-grid__col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .l-grid__col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .l-grid__col-sm-offset-6 {
    margin-left: 50%;
  }

  .l-grid__col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .l-grid__col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .l-grid__col-sm-offset-9 {
    margin-left: 75%;
  }

  .l-grid__col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .l-grid__col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}


.l-grid__row.reverse-xs {
  flex-direction: column-reverse;
  @media only screen and (min-width: $md-min) {
    flex-direction: row;
  }
}

@media only screen and (min-width: $md-min) {

  .hide-xs{
    display:flex !important;
  }
  .hide-md{
    display:none !important;
  }
  .l-grid__col-md,
  .l-grid__col-md-1,
  .l-grid__col-md-2,
  .l-grid__col-md-3,
  .l-grid__col-md-4,
  .l-grid__col-md-5,
  .l-grid__col-md-6,
  .l-grid__col-md-7,
  .l-grid__col-md-8,
  .l-grid__col-md-9,
  .l-grid__col-md-10,
  .l-grid__col-md-11,
  .l-grid__col-md-12,
  .l-grid__col-md-offset-0,
  .l-grid__col-md-offset-1,
  .l-grid__col-md-offset-2,
  .l-grid__col-md-offset-3,
  .l-grid__col-md-offset-4,
  .l-grid__col-md-offset-5,
  .l-grid__col-md-offset-6,
  .l-grid__col-md-offset-7,
  .l-grid__col-md-offset-8,
  .l-grid__col-md-offset-9,
  .l-grid__col-md-offset-10,
  .l-grid__col-md-offset-11,
  .l-grid__col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
  }

  .l-grid__col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .l-grid__col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .l-grid__col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .l-grid__col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .l-grid__col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .l-grid__col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .l-grid__col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .l-grid__col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .l-grid__col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .l-grid__col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .l-grid__col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .l-grid__col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .l-grid__col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .l-grid__col-md-offset-0 {
    margin-left: 0;
  }

  .l-grid__col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .l-grid__col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .l-grid__col-md-offset-3 {
    margin-left: 25%;
  }

  .l-grid__col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .l-grid__col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .l-grid__col-md-offset-6 {
    margin-left: 50%;
  }

  .l-grid__col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .l-grid__col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .l-grid__col-md-offset-9 {
    margin-left: 75%;
  }

  .l-grid__col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .l-grid__col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: $lg-min) {

  .l-grid__col-lg,
  .l-grid__col-lg-1,
  .l-grid__col-lg-2,
  .l-grid__col-lg-3,
  .l-grid__col-lg-4,
  .l-grid__col-lg-5,
  .l-grid__col-lg-6,
  .l-grid__col-lg-7,
  .l-grid__col-lg-8,
  .l-grid__col-lg-9,
  .l-grid__col-lg-10,
  .l-grid__col-lg-11,
  .l-grid__col-lg-12,
  .l-grid__col-lg-offset-0,
  .l-grid__col-lg-offset-1,
  .l-grid__col-lg-offset-2,
  .l-grid__col-lg-offset-3,
  .l-grid__col-lg-offset-4,
  .l-grid__col-lg-offset-5,
  .l-grid__col-lg-offset-6,
  .l-grid__col-lg-offset-7,
  .l-grid__col-lg-offset-8,
  .l-grid__col-lg-offset-9,
  .l-grid__col-lg-offset-10,
  .l-grid__col-lg-offset-11,
  .l-grid__col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
  }

  .l-grid__col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .l-grid__col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .l-grid__col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .l-grid__col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .l-grid__col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .l-grid__col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .l-grid__col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .l-grid__col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .l-grid__col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .l-grid__col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .l-grid__col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .l-grid__col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .l-grid__col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .l-grid__col-lg-offset-0 {
    margin-left: 0;
  }

  .l-grid__col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .l-grid__col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .l-grid__col-lg-offset-3 {
    margin-left: 25%;
  }

  .l-grid__col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .l-grid__col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .l-grid__col-lg-offset-6 {
    margin-left: 50%;
  }

  .l-grid__col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .l-grid__col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .l-grid__col-lg-offset-9 {
    margin-left: 75%;
  }

  .l-grid__col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .l-grid__col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

