@use '../../0-settings/settings' as s;

.c-back-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: s.$size--500;
  text-decoration: none;
  font-size: s.$font-size--200;
}

.c-back-link__icon {
  margin-right: s.$size--200;
}

.c-back-link__label {
  text-decoration: underline;
}
