@use '../../0-settings/settings' as s;

.c-inline-notification {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: s.$size--400 0;
  padding: s.$size--300;
  border-radius: s.$border-radius--default;
}

.c-inline-notification__icon {
  flex: 0 0 s.$size--500;
  margin-right: s.$size--200;
}

.c-inline-notification__message {
  font-size: s.$font-size--200;
  font-weight: s.$weight--semi;
}

.c-inline-notification__dismiss-btn {
  margin-left: auto;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

// Info
.c-inline-notification--info {
  background-color: s.$color--info-light;

  .c-inline-notification__icon {
    color: s.$color--info;
  }
}

// Success
.c-inline-notification--success {
  background-color: s.$color--success-light;

  .c-inline-notification__icon {
    color: s.$color--success;
  }
}

// Warning
.c-inline-notification--warning {
  background-color: s.$color--warning-light;

  .c-inline-notification__icon {
    color: s.$color--warning;
  }
}

// Error
.c-inline-notification--error {
  background-color: s.$color--error-light;

  .c-inline-notification__icon {
    color: s.$color--error;
  }
}
