@use '../../0-settings/settings' as s;

// Public vars
$icon-size--default: s.$size--500;
$icon-size--small: s.$size--400;
$icon-size--large: s.$size--600;

// Regular size
.c-icon {
  width: $icon-size--default;
  height: $icon-size--default;
  fill: currentColor;
}

// Size modifiers
.c-icon--small {
  width: $icon-size--small;
  height: $icon-size--small;
}

.c-icon--large {
  width: $icon-size--large;
  height: $icon-size--large;
}

// Colour modifiers
.c-icon--primary {
  color: s.$color--primary;
}

.c-icon--body-text {
  color: s.$color--body-text;
}

.c-icon--white {
  color: s.$color--white;
}

.c-icon--success {
  color: s.$color--success;
}

.c-icon--error {
  color: s.$color--error;
}

.c-icon--info {
  color: s.$color--info;
}

// Rotate icon
.c-icon {
  transform: rotate(0deg);
  transition: transform s.$speed--default s.$easing--default;
}

.c-icon--rotated {
  transform: rotate(180deg);
}

// Spinner

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.c-icon--spinner {
  animation: infinite-spinning 0.7s infinite linear;
}
