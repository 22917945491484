/*------------------------------------*\
    #HOME - PAGE SPECIFIC
\*------------------------------------*/

.homepage {

    .content__left {
        @extend %layout-left;
        .action span {
            @extend %font-size-m;
        }
    }

    .content__right {
        @extend %layout-right;
        .action span {
            @extend %font-size-m;
            // @extend %font-weight-bold;
        }
        @include media('small-down') {
            padding-left: 1rem!important;
        }
    }

    @include media ('small-down') {
        .content__bottom,
        .content__right,
        .content__left {
            padding-top:1em;
        }
        .content__left {
            padding-bottom:1em;
        }
    }
}
