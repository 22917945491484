@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-panel-sub-navigation {
  display: flex;
}

.c-panel-sub-navigation__heading {
  flex: 1;
}
.c-panel-sub-navigation__heading:focus {
  outline: unset;
}
.c-panel-sub-navigation__heading:focus-visible {
  outline: auto;
}

.c-panel-sub-navigation__buttons {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.c-panel-sub-navigation--inline {
  align-items: center;
  .c-panel-sub-navigation__buttons {
    flex: 0 0 auto;
  }
}

.c-panel-sub-navigation--title-above {
  flex-wrap: wrap;
  .c-panel-sub-navigation__heading {
    flex-basis: 100%;
    margin-bottom: 24px;
  }
}

.c-panel-sub-navigation--title-below {
  flex-wrap: wrap;
  .c-panel-sub-navigation__buttons {
    order: 1;
    flex-basis: 100%;
    margin-bottom: 24px;
  }
  .c-panel-sub-navigation__heading {
    order: 2;
  }
}
