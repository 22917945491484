/**
* Font family, weights and sizes/scales
*/

// Update with primary/secondary project font families
$family--primary: 'Source Sans Pro', 'Helvetica Neue', sans-serif;

// Update with available font weights
$weight--regular: 400;
$weight--semi: 600;
$weight--bold: 700;

$text-underline-offset: 5px;
$text-decoration-thickness: 1px;

/**
* Font size scale
*/

/*
* Root size - used to calculate other sizes in the scale.
* NOTE: this is a base for the scale, and is not directly applied to an element.
*/
$_root-font-size: 1.6rem; // 16px

$font-size--50: $_root-font-size * 0.625; // 10px
$font-size--100: $_root-font-size * 0.75; // 12px
$font-size--200: $_root-font-size * 0.875; // 14px
$font-size--300: $_root-font-size; // 16px
$font-size--400: $_root-font-size * 1.125; // 18px
$font-size--500: $_root-font-size * 1.25; // 20px
$font-size--600: $_root-font-size * 1.5; // 24px
$font-size--700: $_root-font-size * 1.875; // 30px
$font-size--800: $_root-font-size * 2.25; // 36px
$font-size--900: $_root-font-size * 3; // 48px
$font-size--1000: $_root-font-size * 3.75; // 60px
$font-size--1100: $_root-font-size * 4.5; // 72px

// Line height options
$line-height--standard: 1.3;
$line-height--narrow: 1.2;
$line-height--large: 1.5;
