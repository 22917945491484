/*------------------------------------*\
  Additional components:
\*------------------------------------*/

@use '../../0-settings/settings.scss' as s;
@use '../../1-mixins/_breakpoints.scss';

/**
* Region content page specific
*/

.region-content-page {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  a {
    text-decoration: underline;
  }
}
/**
 * Link-like components
 */

.link-like:hover {
  color: $base-link-hover-color;
  text-decoration: none;
}

.link-like {
  outline: 0;
  text-decoration: none;
  color: $base-link-color;
  cursor: pointer;
}

/**
  Banner for warnings within the page content area:
*/

.inner-page-banner {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  line-height: 2.5rem;

  .inner-page-banner__link {
    background-image: none;
  }
}

.inner-page-banner__message {
  font-size: 1.1rem;
}

.inner-page-banner__message-text {
  display: inline-block;
  line-height: 1.4;
  vertical-align: middle;
  width: calc(100% - 100px);
}

.inner-page-banner__icon {
  display: inline-block;
  font-size: 2.5rem;
  margin-right: 10px;
}

@media screen and (max-width: 599px) {
  .inner-page-banner {
    padding: 5px 40px 5px 15px;
    line-height: 1;
  }

  .inner-page-banner__message {
    padding-left: 0;
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .inner-page-banner__icon {
    left: 0;
    top: 5px;
    font-size: 1.8rem;
    position: inherit;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .inner-page-banner__link-wrap {
    position: absolute;
    padding: 0;
    width: auto;
    line-height: 1;
    right: 15px;
    font-size: 0.8rem;
    bottom: 13px;
  }

  .inner-page-banner__link span {
    display: inline-block;
  }
}

/**
  Animate element in on page load:
*/

.banner-text-animation {
  animation-name: fadeInRight;
  animation-duration: 600ms;
  animation-fill-mode: both;
  animation-delay: 1000ms;
}

.banner-link-animation {
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-fill-mode: both;
  animation-delay: 1700ms;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/**
  Heading blocks that contain buttons/other elements inline:
*/

.heading-block {
  margin-bottom: 25px;

  .field--dropdown {
    float: right;
    margin: 0;
    width: 100%;
    max-width: 280px;
  }
}

.heading-block__title {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
  margin-bottom: 0;

  @include up-to('medium') {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .i-arrow-r {
    font-size: 0.7em;
    margin-left: 5px;
  }
}

.heading-block__btn {
  display: inline-block;
  vertical-align: middle;
  padding: 7px 15px;
  margin-bottom: 0;
}

@media screen and (max-width: 670px) {
  .heading-block .field--dropdown {
    width: 100% !important;
    max-width: none;
    float: none;
    margin-top: 20px;
  }
}

/**
  Heading block with big icon
*/

.heading-block--big-icon {
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;

  .big-icon {
    position: absolute;
    left: 0;
    top: 3px;
    width: 25px;
  }

  .heading-block__title {
    line-height: 1;
    margin: 0 0 5px;
  }

  .heading-block__sub-heading {
    line-height: 1;
    margin: 0;
  }
}

/**
  Single heading with icon
*/

.icon-heading i {
  display: inline-block;
  font-size: 1.8rem;
  vertical-align: middle;
}

/**
  List table (used for listing routes)
*/

.list-table {
  border: none;

  td {
    border: none;
  }

  tr:nth-child(odd) td {
    border-bottom: 1px solid #ddd;
  }

  th {
    border: none;
    border-bottom: 1px solid #ddd;
  }

  th {
    vertical-align: bottom;
  }

  td {
    padding: 15px;
  }
}

.list-table--selectable-rows {
  tr:hover {
    border-color: #ddd;
  }

  &.list-table--blue .list-table__active-row {
    border-color: $ms-blue;
  }

  .list-table__content-row {
    cursor: pointer;
  }
}

.list-table__table-title {
  margin: 0;
}

.list-table__content-col {
  text-align: center;
}

.list-table__title {
  color: $ms-blue-dark;
  margin: 0;
}

.list-table__content-row {
  border-left-width: 4px;
  border-left-style: solid;
  border-color: #fff;
  transition: border 300ms ease;
  position: relative;
  border-bottom: 1px solid #ddd;
  min-height: 77px;
}

.list-table__show-on-active-row {
  display: none;
  margin-top: 5px;
}

.list-table__active-row .list-table__show-on-active-row {
  display: block;
}

.list-table--commutes {
  td,
  th {
    font-size: 0.8rem;
    padding: 10px 5px;
  }

  tr td:first-child {
    padding-left: 0;
  }
}

.list-table__commute-details {
  font-weight: 600;
  position: relative;
  padding-left: 17px;

  .icon-marker {
    position: absolute;
    left: 0;
    top: 0;
  }

  a {
    display: block;
  }
}

.list-table__time-col {
  position: relative;
  text-align: center;
  min-width: 55px;

  a {
    position: absolute;
    right: 5px;
    bottom: 12px;

    i {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
}

.list-table_time-col {
  width: 15%;
  text-align: center;
}

.list-table .pill:hover {
  color: #fff;
}

.header-box {
  max-width: 65%;
  padding: 0.75em;
}

.route-container {
  margin-bottom: 36px;

  .header-row {
    position: relative;
    border: none;
    border-bottom: 1px solid #ddd;
    height: auto;
  }

  .text-holder {
    width: calc(100% - 224px);
    max-width: 85%;
    padding: 0.75em;

    .error-message {
      color: $ms-red;

      a {
        text-decoration: underline;
        color: $ms-red;
      }
    }
  }

  .time-box {
    width: 35%;
    min-width: 224px;
    height: auto;
    position: absolute;
    top: 0px;
    right: 0.75em;

    .inner-box {
      text-align: center;
      padding: 0.75em;
    }

    .left.public-transport {
      display: inline-block;
      width: 50%;
      min-width: 128px;
      white-space: nowrap;
    }

    .left {
      display: inline-block;
      width: 50%;
      min-height: 48px;
      white-space: nowrap;
    }

    .right {
      display: inline-block;
      width: 50%;
      position: absolute;
      bottom: 0px;
    }
  }

  .button-holder {
    align-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 50px 50px;
    margin: 0.75em;
  }

  .ev-chargers-info {
    width: calc(100% + 100px);
  }

  .list-table__content-row:hover {
    border-color: #ddd;
  }

  .list-table__content-row.list-table__active-row {
    border-left: $ms-blue 4px solid;
  }

  .public-transport.ev-chargers-info {
    min-height: 24px;
  }
}

.save-route-link {
  padding-top: 0px;
  position: absolute;
  bottom: 10px;
  right: 0.75em;
}

.delete-route-icon {
  height: 20px;
  width: auto;
  margin-left: 12px;
}

.radio-btn-row {
  display: block;
  margin-bottom: 1rem;
  margin-bottom: 16px;
  float: none;
  font-size: large;
}

.radio-btn-label {
  display: contents;
}

.route-alert-period {
  margin-right: 15px;
}

.bin-icon {
  margin-left: 10px;
  margin-bottom: 5px;
}

.bin-icon:hover {
  color: $ms-medium-red;
}

@media screen and (max-width: 599px) {
  .list-table tr th:first-child {
    padding-left: 0;
  }

  .list-table__table-title {
    font-size: 1.1rem;

    i {
      font-size: 30px;
    }
  }

  .long-title-hide {
    display: none;
  }

  .long-title-show {
    display: inline-block;
  }

  .long-title-show::first-letter {
    text-transform: uppercase;
  }

  .list-table th.list-table__content-col {
    vertical-align: middle;
  }

  .list-table .list-table__content-col {
    line-height: 22px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .list-table__title {
    font-size: 0.9rem;
  }

  .list-table__show-on-active-row {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .list-table.has-btns .list-table__title-col {
    position: relative;
    padding-bottom: 55px;
  }

  .list-table__active-row.has-trend .list-table__title-col {
    padding-bottom: 80px;
  }

  .list-table__content-row.has-ev-chargers .list-table__title-col {
    padding-bottom: 15px;
  }

  .list-table__active-row.has-trend.has-ev-chargers .list-table__title-col {
    padding-bottom: 116px;
  }

  .list-table__mobile-full-width {
    position: absolute;
    width: max-content;
    left: 0;
    bottom: 15px;
    padding-left: 15px;
  }

  .route-container .pill {
    font-size: 0.7rem;
    padding: 4px 10px;
  }
}

@media screen and (min-width: 600px) {
  .list-table__title-col {
    width: 55%;
  }

  #commute-finder-map {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 440px) {
  .grid > * {
    font-size: small;
  }

  .route-container .text-holder {
    width: calc(100% - 45%);
    max-width: 85%;
    padding: 0.75em;
  }

  .route-container .time-box {
    min-width: 182px;
    min-height: 26px;
    top: 8px;
    right: 0.3em;

    .inner-box {
      padding: 0.3em;
    }

    .left.public-transport {
      display: inline-block;
      width: 50%;
      min-width: 94px;
      white-space: nowrap;
    }

    .left {
      min-height: 0px;
    }
  }
}

@media screen and (max-width: 380px) {
  .route-container .text-holder {
    width: calc(100% - 47%);
    max-width: 85%;
    padding: 0.75em;
  }

  .route-container .time-box {
    min-width: 156px;
    top: 8px;
    right: 0.1em;

    .inner-box {
      padding: 0.75em;
    }

    .left.public-transport {
      display: inline-block;
      width: 50%;
      min-width: 94px;
      white-space: nowrap;
    }
  }

  .list-table__table-title {
    font-size: 0.9rem;
  }

  .list-table__table-title i {
    font-size: 28px;
  }

  .route-container .button-holder {
    align-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 50px 50px;
    margin: 0.7em;
  }
}

/**
  Additional button styles not included in pattern lib
*/

.btn--green {
  background: $ms-green;
  color: #fff;
}

.btn--green-light {
  background: $ms-green-light;
  color: #fff;
}

.btn--green-light:hover,
.btn--green:hover {
  color: #fff;
}

/**
  Button with logo next to it
*/

.logo-with-btn__logo-wrap {
  display: inline-block;
  vertical-align: middle;
  height: 42px;
  margin: 0 5px 0 0;

  img {
    display: block;
    height: 100%;
    width: auto;
  }
}

.logo-with-btn__btn {
  margin: 0;
  vertical-align: middle;
}

/**
  Traffic cameras display
*/

.cameras-display__header {
  position: relative;
  min-height: 230px;
}

.cameras-display__nav {
  margin-bottom: 10px;
}

.cameras-display .cameras-display__details {
  width: 50%;
  float: left;
  padding: 20px 30px;
}

.cameras-display__image {
  float: right;
  width: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.cameras-display__heading {
  font-size: 2rem;
  margin-bottom: 5px;
}

.cameras-display__sub-heading {
  font-size: 1.1rem;
}

.cameras-display__text {
  font-size: 0.9rem;
}

.cameras-display__camera-list {
  margin: 0;
  padding: 15px 8px 10px;
  list-style: none;
}

.cameras-display__camera-list-item {
  list-style: none;
  float: left;
  width: calc(33.33% - 16px);
  margin: 0 8px 10px;

  &.active .cameras-display__camera-list-image {
    border: 2px solid $ms-green-light;
  }
}

.cameras-display__camera-list-image {
  display: block;
  width: 100%;
  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 5px;
  border: 2px solid $ms-green-extra-light;

  img {
    display: none;
  }
}

.cameras-display__camera-list-link {
  display: block;
  text-align: center;
}

@media screen and (max-width: $breakpoint-small-max) {
  .cameras-display__nav {
    font-size: 0.9rem;
  }

  .cameras-display__heading {
    font-size: 1.5rem;
  }

  .cameras-display__camera-list-item {
    width: calc(50% - 16px);
  }
}

@media screen and (max-width: 479px) {
  .cameras-display__header {
    position: relative;
    padding-right: 0;
    min-height: 0;
  }

  .cameras-display__details {
    position: relative;
    width: 100%;
    padding: 15px;
  }

  .cameras-display__sub-heading {
    margin-bottom: 0;
  }

  .cameras-display .cameras-display__details {
    width: 100%;
  }

  .cameras-display .cameras-display__text {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 99;
    padding: 35px 15px 15px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  }

  .cameras-display__image {
    position: static;
    width: 100%;
    height: auto;
    background-image: none !important;

    img {
      display: block !important;
      width: 100%;
      height: auto;
    }
  }

  .cameras-display__camera-list-item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .cameras-display__camera-list-image {
    height: 220px;
  }
}

@media screen and (min-width: 480px) and (max-width: 600px) {
  .cameras-display__image img {
    height: 100%;
    width: auto;
  }
}

@media screen and (min-width: 960px) {
  .cameras-display__camera-list-image {
    height: 150px;
  }
}

/**
  List of delays, other events...
*/

.event-list {
  padding: 0;
  margin: 0 0 40px;
  list-style: none;
}

.news-list__item {
  @extend .narrow-hatched-baseline;
  list-style: none;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 10px;

  p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}

.event-list__item {
  @extend .narrow-hatched-baseline;
  list-style: none;
  position: relative;
  padding-left: 28px;
  padding-bottom: 15px;
  margin-bottom: 15px;

  p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}

.event-list__item-icon {
  position: absolute;
  left: 0;
  top: 3px;
  background-size: 22px;
}

.event-list__item-heading {
  margin-bottom: 5px;
  font-size: 1.1rem;
  line-height: 1.1;
}

.event-list__item-sub-heading {
  margin-bottom: 10px;
}

.event-list__link-icon {
  position: absolute;
  bottom: 15px;
  right: 0;

  i {
    font-size: 1rem;
  }
}

.event-list__item--with-map {
  @extend .narrow-hatched-baseline;
  padding-bottom: 10px;
  padding-left: 0;
  margin-bottom: 10px;
  list-style: none;
  position: relative;
  margin-top: 16px;

  .event-list__item-sub-heading {
    font-size: 1.2rem;
    color: #666;
  }
}

.event-list__icon,
.event-list__map-image {
  display: block;
  width: 155px;
  height: 102px;
  float: left;
  margin-right: 20px;
  border-radius: 8px;
}

.event-list__map-image--medium {
  width: 175px;
  height: 140px;
}

.event-list__icon.icon-only,
.event-list__map-image.icon-only {
  width: 26px;
  height: 34px;
  margin-top: 8px;
}

.event-list__edit-route {
  margin-left: 15px;
}

.field--dropdown .incident-sort .dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: initial;
}

.field--dropdown .incident-sort .dropdown--dark {
  background-position: 3% -80px;
  padding-left: 38px;
}

@media screen and (max-width: 599px) {
  .event-list__map-image {
    display: none;
  }
}

.event-list .default-hidden {
  display: none;
}

.event-list.active .default-hidden {
  display: inherit;
}

/**
  Buttons displayed as pill boxes
*/

.pill-buttons .btn {
  font-size: 0.9rem;
  margin: 0 5px 0 0;
  padding: 7px 13px 7px 7px;
  text-align: left;

  i {
    width: 18px;
    margin-right: 2px;
    float: left;
    display: block;
  }
}

.pill__text {
  width: calc(100% - 20px);
  line-height: 1.1;
  display: block;
  float: left;
  margin-top: 1px;
}

/**
  Definition list
*/

.def-list {
  border-top: 1px solid $ms-grey-light;
  border-bottom: 1px solid $ms-grey-light;
  padding: 20px 0;
  margin: 0 0 40px;
  overflow: hidden;

  dd,
  dt {
    float: left;
    padding: 0;
    margin: 0 0 10px;
    border: none;
  }

  dt {
    width: 40%;
    clear: left;
  }

  dd {
    width: 60%;
    text-align: left;
  }

  .def-list__big-item {
    font-size: 20px;
  }
}

/**
  Broken blue border top effect
*/

.broken-blue-border {
  position: relative;
  padding-top: 20px;
}

.broken-blue-border:before {
  height: 2px;
  display: block;
  width: 22.5%;
  background: rgba(37, 117, 174, 0.5);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}

.broken-blue-border:after {
  display: block;
  content: '';
  background: rgba(37, 117, 174, 0.25);
  height: 1px;
  width: 72.5%;
  top: 0;
  right: 0;
  position: absolute;
}

/**
 Regional commute finder form
*/

$mapWidth: 65%;

.commute-finder {
  padding-right: $mapWidth;
  position: relative;
}
button.field-btn {
  font-size: medium;
}

@keyframes jiggle {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-7px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(7px, 0, 0);
  }
}

.jiggle {
  animation-name: jiggle;
  animation-duration: 800ms;
  animation-fill-mode: both;
}

.commute-finder__form {
  padding: 30px 30px 30px 70px;

  label {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
  }

  .field--has-btn input {
    color: #00456b;
    border: none;
    height: 46px;
    padding-left: 15px;

    &:focus {
      outline: none;
    }
  }

  button {
    margin-bottom: 0;
  }

  .field-btn {
    right: 0;
    bottom: 0;
  }
}

.btn--green {
  background: #59b357;
  font-size: 18px;
  line-height: 1;
  transition: background 150ms ease;

  &:hover {
    background: #409a40;
  }
}

.field--text {
  position: relative;

  .i-map-marker-white {
    position: absolute;
    left: -42px;
    top: 31px;
    background-size: 30px;
    width: 30px;
    height: 43px;
    color: $ms-blue-extra-dark;
    text-align: center;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
  }
}

.field--has-btn {
  position: relative;

  input {
    outline: none;
    padding-right: 50px;
    padding-left: 10px;
    text-overflow: ellipsis;
  }
}

.field-btn {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 100%;
  line-height: 38px;
  border-radius: 0 0.25rem 0.25rem 0;
  text-align: center;
  padding: 0;
}

.commute-finder__form .field-btn {
  height: 46px;
  line-height: 46px;
}

.commute-finder__map-container {
  background: #60ace3;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: $mapWidth;
  overflow: hidden;
}

#commute-finder-map .leaflet-google-layer {
  height: 100% !important;
}

.validation-wrapper {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 300ms ease;
}

.validation-message {
  padding: 0 0 15px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1em;
  color: #005485;

  &.reversed-colours {
    color: #fff;
  }

  i {
    font-size: 1.1rem;
    margin-right: 2px;
  }
}

.commute-finder__form .validation-message {
  font-size: 0.9rem;

  i {
    margin-top: -2px;
  }
}

.sidebar #commute-finder .validation-message {
  font-size: 0.8rem;
}

@media screen and (min-width: 600px) and (max-width: 980px) {
  $mapWidth: 50%;

  .commute-finder {
    padding-right: $mapWidth;
  }

  .commute-finder__map-container {
    width: $mapWidth;
  }
}

@media screen and (max-width: 599px) {
  .commute-finder {
    padding-right: 0;

    h2 {
      display: none;
    }

    .i-map-marker-white {
      display: none;
    }
  }

  .commute-finder__form {
    padding: 15px;
  }
}

@media screen and (min-width: 964px) {
  .home-page__commute-finder .field-btn {
    height: 44px;
    line-height: 44px;
  }
}

/**
  Journey map
*/

.map-wrapper {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

#key-journey-map,
#map {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.debug-route:hover {
  stroke: #800;
}

/**
  Map views, map panels
*/

.mode-switcher {
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 2;

  span {
    margin: 0 10px 0 5px;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    height: 26px;
    vertical-align: middle;
  }
}

.mode-select {
  font-size: 1.4rem;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid transparent;
  color: $ms-grey-mid-light;
  cursor: default;

  &:hover {
    color: $ms-grey-mid-light;
  }

  &.enabled {
    color: $ms-green-light;
    cursor: pointer;
    &:hover {
      border: 1px solid $ms-green-light;
      color: $ms-green-light;
    }
  }

  &.selected {
    background: $ms-green-light;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }

  i {
    margin: 0 6px;
  }
}

.map-panel-btns .mode-switcher {
  display: inline-block;
  margin: 0 5px;
  position: initial;
  padding: 3px 10px;

  .mode-select {
    font-size: 1.1rem;
  }

  .mode-select i {
    margin: 0 5px;
  }
}

.leaflet-control-attribution {
  display: none;
}

.panel--nested {
  position: absolute;
  width: 100%;
  overflow: auto;
  height: 100%;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 11;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-100%);
  transition: transform 250ms ease;

  &.active {
    transform: translateX(0);
  }

  .panel-inner {
    width: 100%;
    min-height: 100%;
  }
}

.text-max-210 {
  display: inline-block;
  max-width: 210px;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

@media screen and (min-width: 960px) {
  .map-view-btn {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .panel-is-expanded {
    overflow: hidden;
    position: fixed;
  }

  .mode-switcher {
    display: none;
  }
  .panel-is-expanded .map-panel-btns .mode-switcher {
    display: inline-block;
  }

  $mobileHeaderHeight: 64px;

  .panel {
    position: fixed;
    top: $mobileHeaderHeight;
    left: 0;
    z-index: 9;
    width: 100%;
    height: calc(100% - #{$mobileHeaderHeight}); // Allow for header
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    transition: transform 250ms ease;

    &.active {
      transform: translateX(0);
      background: #fff;
    }

    .panel-inner {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  .hide-panel-btn {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 10;
    background: $ms-blue-extra-dark;
    color: #fff;
    padding: 12px 15px 12px 25px;
    border-radius: 0 3px 3px 0;
    font-size: 0.9rem;
    transform: translate3d(0, 0, 0);

    i {
      position: absolute;
      left: 7px;
      top: 14px;
    }

    &:hover {
      color: #fff;
    }
  }

  .panel-baseline {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    transform: translate3d(0, 0, 0);
  }

  .panel-footer-bar {
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    position: relative;
    min-height: 45px;
  }

  .panel-footer-bar--green {
    background: #56b555;
    color: #fff;
  }

  .panel-footer-bar__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    padding: 14px 15px;
  }

  .panel-footer-bar__text-block {
    display: inline-block;
    margin: 0 10px;
    color: #fff;
  }

  .map-panel-btns {
    text-align: center;
    transform: translate3d(0, 0, 0);

    .btn {
      display: inline-block;
      font-size: 0.9rem;
      margin: 0 5px 15px;
      padding-left: 10px;
      padding-right: 10px;

      i {
        font-size: 14px;
        vertical-align: middle;
      }
    }

    .hide {
      display: none;
    }
  }

  .mobile-marker-panel {
    display: block;
    padding: 8px 15px;
    margin: 0 auto 5px;
    position: relative;
    background: #fff;
    border: none;
    width: 100%;
    max-width: 300px;
    border-radius: 4px;
    text-align: left;
    font-size: 14px;
    overflow: hidden;
    color: $ms-blue-dark;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.hide {
      display: none;
    }

    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
      height: 25px;
      width: auto;
    }

    i {
      position: absolute;
      display: block;
      overflow: hidden;
      right: 0;
      top: 0;
      margin: 0;
      width: 35px;
      height: 100%;
      text-align: center;
      background: #4887b5;
      color: #fff;
      line-height: 41px;
    }
  }
}

@media screen and (min-width: 600px) {
  .panel--nested {
    max-width: 300px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  $mobileHeaderHeight: 80px;

  .panel {
    top: $mobileHeaderHeight;
    height: calc(100% - #{$mobileHeaderHeight}); // Allow for header
  }

  .commute-finder .hide-panel-btn,
  .commute-finder .map-view-btn,
  .commute-finder .panel-baseline {
    display: none;
  }

  .commute-finder__map-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    transform: none;
    transition: none;
    left: auto;
  }
}

/**
  Internal map panel: camera
*/

.camera-panel {
  .cameras-display__header {
    padding: 0 20px 20px;
    min-height: 0;
  }

  .cameras-display__details {
    padding: 0;
  }

  .cameras-display__heading {
    position: relative;
    padding-right: 35px;
    line-height: 1;

    .highway {
      right: 0;
    }
  }
}

.camera-panel-img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 1px; // iOS hack
}

.overlay-back {
  display: block;
  padding: 15px;
  font-size: 0.9rem;

  i {
    margin: -4px 10px 0 0;
  }
}

@media screen and (min-width: 600px) {
  .camera-panel .cameras-display__header {
    min-height: 204px;
  }
}

/**
  Internal map panel: individual delay
*/

.overlay-delay-details {
  padding: 10px 20px 20px;

  .btn--secondary {
    color: $ms-blue-dark !important;
  }
  &.timsign {
    color: $dark-grey;
    background: #fff;
    padding-top: 20px;
  }
}

.vms-overlay-grid {
  margin: 0 5px;
  padding: 10px 0;
}

@media screen and (min-width: 600px) {
  .overlay-delay-details {
    h3 {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      line-height: 1.4;
    }

    h5 {
      margin-bottom: 0;
    }
  }
}

/**
  Delays on individual route panel (mobile only, in sidebar on desktop)
*/

.white-panel {
  background: #fff;
}

.white-panel-heading {
  position: relative;
  display: block;
  text-align: center;
  background: #004772;
  color: #fff;
  padding: 10px 60px;
  margin: 0;
  font-size: 22px;

  a {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    padding: 16px 10px;
    color: #fff;
    font-size: 14px;

    i {
      margin-top: -4px;
    }
  }
}

.white-panel-content {
  padding: 20px;
}

.no-updates {
  display: none;
}

.no-children {
  .no-updates {
    display: block;
  }
  .event-list {
    display: none;
  }
}

/**
  Max-width content area
*/

.max-965 {
  height: 100%;
  width: 100%;
  max-width: 1005px;
  margin: 0 auto;
  padding: 25px 20px 60px;
  position: relative;
}

/**
  Home Page tabbed menu
*/

.tab-menu {
  float: left;
  width: 80%;
  min-width: 300px;

  .tab-container {
    margin-bottom: 0px;

    .menu-item {
      min-width: 130px;
      padding: 10px 14px 14px 20px;
      margin-right: 0px;
      background: #236fa6;
      color: #fff;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
    }

    .selected {
      background: #fff;
      color: #005485;
      cursor: default;
    }
  }
}

.sign-in-btn {
  min-width: 35%;
  background: #236fa6;
  color: #fff;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 12px;
  margin-bottom: 1rem;

  &:hover {
    background: #005485;
  }
}

.bottom-margined {
  margin-bottom: 1rem;
}

.header-btn {
  margin-bottom: 0px;
  float: right;
}

.inline-header {
  display: inline-block;
}

@media screen and (max-width: 355px) {
  .header-btn {
    font-size: small;
    padding: 8px;
    margin-top: 4px;
  }
}

/**
  SVG map (used on home page)
*/

.icon-list {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

.icon-list__item {
  display: block;
  margin-top: 0;
  border-bottom: 1px solid #efefef;
}

.icon-list__item a {
  display: block;
  padding: 10px 5px;
}

#home-page {
  background: #fff;
}

#svg-map-wrap,
.home-page__intro {
  display: none;
}

.home-page__commute-finder-disclaimer {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.home-page__commute-finder-disclaimer p {
  line-height: 1.4285714286;
  font-size: 0.875rem;
}

@media screen and (min-width: 965px) {
  .home-page__content {
    position: absolute;
    left: 50px;
    top: 50px;
    z-index: 9;
    width: 500px;
  }

  .home-page__intro {
    display: block;
    color: #fff;
    margin-bottom: 30px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

    h1 {
      font-size: 48px;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
    }
  }

  .home-page__commute-finder {
    float: left;
    width: 80%;
    min-width: 300px;
    padding: 20px;
    background: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

    h2 {
      font-size: 32px;
    }

    .field--has-btn input {
      border: none;
      height: 44px;
      padding-left: 15px;
    }

    .field-btn {
      bottom: 0;
      right: 0;
    }

    button {
      margin: 0;
    }
  }

  .home-page__commute-finder-intro {
    font-size: 1.4rem;
    padding-top: 15px;
    margin-top: 30px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 2px;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  #svg-map-wrap {
    display: block;
    float: right;
    margin: 0 0 20px;
    height: 600px;
    width: 470px;
    position: relative;
  }

  #svg-map {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  .map-region {
    cursor: pointer;
    fill: #e8e0d8;
    stroke: #b9b8b8;
    stroke-miterlimit: 10;
    transition: all 300ms ease;
  }

  .map-region:hover {
    fill: $ms-green-light;

    &.no-region {
      fill: #e8e0d8;
    }
  }

  .map-labels-wrap {
    pointer-events: none;
  }

  .map-label {
    display: block;
    padding: 5px 12px;
    background: $ms-blue-extra-dark;
    color: #fff;
    font-size: 0.8rem;
    line-height: 1;
    position: absolute;
    z-index: 999;
  }

  .map__dot {
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 9;
    border-radius: 100%;
    border: 2px solid #236fa6;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    transition: background 200ms ease;

    &:hover {
      background: $ms-green-light;
    }
  }

  .map__sub-region--auckland {
    top: 105px;
    left: 311px;
  }

  .map__sub-region--wellington {
    top: 307px;
    left: 315px;
  }

  .map__sub-region--christchurch {
    top: 416px;
    left: 226px;
  }

  .map__text-link {
    position: absolute;
    font-size: 20px;
    color: #fff;
    z-index: 99;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  }

  .map__text-link--auckland {
    left: -92px;
    top: -4px;
  }

  .map__text-link--wellington {
    left: 5px;
    top: 26px;
  }

  .map__text-link--christchurch {
    left: 32px;
    top: -15px;
  }
}

/**
  Home page region select (mobile only)
*/

@media screen and (min-width: 768px) and (max-width: 964px) {
  .home-page__commute-finder {
    float: left;
    width: 380px;
    margin-right: 40px;
  }

  .home-page__commute-finder-disclaimer {
    margin-top: 0;
    margin-bottom: 0;
  }

  .home-page__mobile-region-select {
    float: left;
    width: calc(100% - 420px);

    h2 {
      margin-bottom: 25px;
    }
  }
}

@media screen and (min-width: 965px) {
  .home-page__mobile-region-select {
    display: none;
  }
}

.MapPage {
  .leaflet-control-geocoder-icon {
    background-size: 20px;
  }
  .leaflet-touch .leaflet-control-geocoder-form input {
    font-size: 16px;
  }
  .leaflet-control-geocoder {
    border-radius: 0;
  }
  .leaflet-touch .leaflet-control-geocoder {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .leaflet-control {
    clear: none;
  }
  .pac-search-box {
    margin-top: 10px;
    width: 246px;
    font-size: 120%;
  }

  .roadworks-cluster,
  .closure-cluster,
  .weight-restriction-icon {
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .leaflet-div-icon {
    background-color: transparent;
    border: none;
  }
  .closure-cluster .cluster-count,
  .roadworks-cluster .cluster-count,
  .weight-restriction-icon .weight-restriction {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-top: 2px;
    line-height: 26px;
    color: white;
  }
  .weight-restriction-icon .weight-restriction {
    font-size: 12px;
    padding-top: 6px;
    color: $dark-grey;
  }
  .camera-cluster,
  .cluster0 {
    background-size: cover;
  }
}

/**
  Share link toolbar
*/

.header-share-links-wrap .grid {
  position: relative;
}

.header-share-links {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  background: #fff;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}

.header-share-links__share-link {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  height: 16px;
  width: auto;

  img {
    display: block;
    height: 100%;
    width: auto;
  }
}

@media (max-width: 599px) {
  .header--section-nav .header__title {
    overflow: hidden;
  }

  .header-share-links-wrap {
    padding: 0;
    margin: 0;
  }

  .header-share-links {
    padding: 5px 1rem;
    width: 100%;
    margin: 0;
    position: static;
    text-align: center;
    border-radius: 0;
  }

  .header-share-links__share-link {
    height: 40px;
    padding: 11px 5px;
  }
}

/**
  Route time map marker
*/

.leaflet-marker-icon.link-stop-icon {
  border-radius: 7px;
  background-color: #236fa6;
  border: 2px solid white;
  cursor: auto;
}

.route-sign-wrapper {
  position: relative;

  .leaflet-popup-content-wrapper,
  .leaflet-popup-content {
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  .route-sign {
    white-space: nowrap;
    padding: 8px;
    background-color: white;
    border: 1px solid lightgrey;
    z-index: 100;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

    i {
      display: inline-block;
      margin-right: 7px;
      font-size: 1.6rem;
      line-height: 1.2;
      vertical-align: top;
    }
    &.key-journey-sign {
      border: none;
    }
  }

  .triangle {
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid lightgray;
    position: absolute;
    bottom: -11px;
    right: 0;
    z-index: 0;

    &.inner {
      border-width: 10px;
      right: 1px;
      bottom: -8px;
      border-right-color: white;
      z-index: 300;
    }
  }
}

.route-info {
  display: inline-block;
  vertical-align: middle;
  color: $ms-blue-dark;
}

.route-time {
  font-size: 12px;
  line-height: 1.4;
  font-weight: bold;
}

.route-distance {
  font-size: 10px;
  line-height: 1.4;
}

.route-has-tolls {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 10px;
}

.route-tolls-apply {
  font-size: 10px;
  font-weight: bold;
}

/**
  File buttons / tiles:
*/
.file-btn {
    position: relative;
    display: block;
    padding: s.$size--400 s.$size--625 s.$size--400 s.$size--400;
    border-radius: 0.25rem;
    margin: 0 0 s.$size--450;
    overflow: hidden;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    font-weight: s.$weight--regular;
    a {
      color: s.$color--white;
    }

    @include breakpoints.tablet-portrait-up {
      display: inline-block;
      min-height: 100px;
      vertical-align: top;
      width: calc(33% - 20px);
      min-height: s.$size--900;
      margin: 0 s.$size--450 s.$size--450 0;
      padding-right: s.$size--400;
      padding-bottom: s.$size--600;
    }

    @include breakpoints.tablet-landscape-up {
      padding-right: s.$size--500;
      padding-bottom: s.$size--400;
    }
  }

  .file-btn--blue {
    border-color: s.$color--jellybean;
  }

  .file-btn--red {
    border-color: s.$color--well-read;
  }

  .file-btn--green {
    border-color: s.$color--fruit-salad;
  }

  .file-btn--yellow {
    border-color: s.$color--tulip-tree;
  }

  .file-btn--orange {
    border-color: s.$color--grenadier;
  }

  .file-btn--light-blue {
    border-color: s.$color--cerulean;
  }

/**
  Tile buttons
*/
.tile-btn {
  position: relative;
  display: block;
  padding: 15px 35px 15px 15px;
  border-radius: 0.25rem;
  margin: 0 0 20px;
  overflow: hidden;
  border-bottom-width: 5px;
  border-bottom-style: solid;
}

.tile-btn--blue {
  border-color: #4887b5;
}

.tile-btn--red {
  border-color: $ms-medium-red;
}

.tile-btn--green {
  border-color: #3ba837;
}

.tile-btn--yellow {
  border-color: #fda128;
}

.tile-btn--orange {
  border-color: #ef572a;
}

.tile-btn--light-blue {
  border-color: #00abdc;
}

.tile-btn__title {
  display: block;
  font-size: 1.3rem;
  line-height: 1.1;
  margin-bottom: 3px;
  font-weight: 400;
}

.tile-btn__byline {
  display: block;
  font-size: 0.85rem;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 5px;
}

.tile-btn__byline,
.tile-btn__title {
  float: left;
  clear: left;
}

.tile-btn__icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 1.4rem;
  line-height: 1;
}

@media (min-width: 600px) {
  .tile-btn {
    display: inline-block;
    min-height: 100px;
    vertical-align: top;
    width: calc(33% - 20px);
    min-height: 95px;
    margin: 0 20px 20px 0;
    padding-right: 15px;
    padding-bottom: 30px;
  }

  .tile-btn__byline,
  .tile-btn__title {
    float: none;
    clear: none;
  }

  .tile-btn:nth-child(3n) {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .tile-btn {
    padding-right: 25px;
    padding-bottom: 15px;
  }
}

/**
  Header banner image
*/

.header--section-nav .header__title {
  .breadcrumb,
  h1 {
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.8);
  }

  .wrapper {
    position: relative;
    z-index: 1;
  }
}

@media (min-width: 960px) and (max-width: 1145px) {
  .header--section-nav {
    padding-bottom: 0;
  }

  .section-nav {
    margin-bottom: 2rem;
  }
}

@include up-to(small) {
  .header__image img {
    width: 150%;
    margin-left: -25%;
    margin-right: -25%;
  }
}

/**
  Regional Updates Sidebar Widget
*/
.regional-updates i {
  background-color: #236fa6;
  border-radius: 100%;
  padding: 10px;
  border: 5px solid #236fa6;
  display: inline-block;
}

.dot-hr {
  border-bottom: 1px dotted #ccc;
  margin-bottom: 15px;
  padding-top: 5px;
}

/**
  Hotspots sidebar
*/
.hotspot-item {
  @extend .narrow-hatched-baseline;
  display: block;
  position: relative;
  padding: 6px 0px 12px 22px;

  .i-arrow-r {
    margin-top: 5px;
  }
}

.hotspot-time {
  font-weight: bold;
  color: $base-font-color;
  display: inline-block;
  vertical-align: middle;
}
.no-hotspots-wrap {
  @extend .narrow-hatched-baseline;
  padding-bottom: 20px;
}
.no-hotspots {
  font-style: italic;
  display: inline-block;
  padding: 6px 15px;
  background: #f7f9e9;
  border-radius: 3px;
}

/**
  Partner Logos
*/
.logo-item {
  display: inline-block;
  height: 50px;
  margin-right: 4%;
  margin-bottom: 10px;
  max-width: 44%;
  vertical-align: top;

  img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    image-rendering: -webkit-optimize-contrast;
  }
}

/**
  Skip to content link
*/
.access-keys:target,
.access:focus {
  position: static;
  visibility: visible;
  width: auto;
  height: auto;
  overflow: visible;
}

.tooltip-wrap {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  line-height: 1;

  &.tip-open .tooltip-tip {
    display: block;
  }

  &.left-tip .tooltip-tip {
    left: 6px;
  }

  &.left-tip .tooltip-tip:before {
    left: 0;
    border-left-width: 0;
    border-right-width: 28px;
  }

  &.right-tip .tooltip-tip {
    left: auto;
    right: 6px;
  }

  &.right-tip .tooltip-tip:before {
    left: auto;
    right: 0;
    border-right-width: 0;
    border-left-width: 28px;
  }
}

.tooltip-icon {
  display: inline-block;
  vertical-align: top;
  margin: 0 2px;
  width: 12px;
  height: 12px;
  line-height: 13px;
  border-radius: 100%;
  font-size: 10px;
  color: #fff;
  background: #236fa6;
  cursor: pointer;
  text-align: center;
}

.tooltip-tip {
  display: none;
  position: absolute;
  bottom: calc(100% + 15px);
  left: -92px;
  z-index: 99;
  background: #236fa6;
  color: #fff;
  padding: 15px;
  font-size: 12px;
  line-height: 1.3;
  width: 284px;
  border-radius: 5px;
  font-weight: 600;
  text-align: left;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 16px);
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 20px solid #236fa6;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

/**
  Table styling
*/
.c-table {
  display: table;
  border: none;
}

.c-table__group-header {
  font-size: s.$font-size--500;
  line-height: s.$font-size--400;
  font-weight: 600;
  margin-bottom: s.$font-size--400;
}

.c-table__header {
  background: none;
  border-bottom: 1px solid s.$color--gray-light;
}

.c-table__column-heading {
  font-weight: s.$weight--bold;
  font-size: s.$font-size--100;
  border: none;
  padding: 0;
  padding-bottom: s.$size--200;
}

.c-table__row {
  border-bottom: 1px solid s.$color--gray-light;

  a {
    text-decoration: underline;
    color: #00456b;
  }
}

.c-table__cell {
  border: none;
  padding: 16px 0;
  position: relative;
}

.c-table__flow-state {
  display: block;
  height: s.$size--200;
  width: s.$size--200;
  border-radius: s.$size--200;
  position: absolute;
  left: -1 * s.$size--200;
  top: s.$size--500;
  transform: translateX(-100%);
}

.c-table__flow-state--red {
  background: s.$color--error;
}
.c-table__flow-state--orange {
  background: s.$color--warning;
}
.c-table__flow-state--green {
  background: s.$color--success;
}

.l-traffic-dashboard__route {
  padding-right: 40px;
}

/**
  Key journeys index
*/
.region-pills__button {
  margin-bottom: 0.4rem;
}

.region-pills__button + .region-pills__button {
  margin-left: 0.1rem;
}

.list-table__small-text {
  font-size: 0.9rem;
}

.hours-mins {
  margin-left: 1px;
}

.journey-group-list {
  th {
    background: none;
    border-bottom: 1px solid s.$color--gray-light;
  }

  td {
    border: none;
    padding: 16px 0;
    position: relative;
  }

  .journey-indicator-col {
    text-align: center;
    padding-right: 0;
    padding-left: 0;
    vertical-align: top;
  }

  .journey-name {
    width: 50%;
  }

  .journey-cost {
    color: $mid-grey;
  }
}

.journey-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;

  &.red {
    background: $ms-red;
  }

  &.yellow {
    background: $ms-yellow;
  }

  &.green {
    background: $ms-green;
  }
}

.journey-group-list__first-direction td {
  border-bottom: 0;
  padding-bottom: 0;
}

.journey-group-list__second-direction td {
  padding-top: 0;
}

.journey-colour-key {
  margin-top: 40px;
}

.journey-colour-key__item {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

@media (max-width: 600px) {
  .journey-group-list td,
  .journey-group-list th {
    font-size: 12px;
    line-height: 1.2;
    padding: 5px 5px 5px 0;
  }

  .journey-group-list .journey-name {
  }
}

/**
  Key journey detail
*/

.journey-metrics {
  text-align: center;
  margin-bottom: 40px;
}

.journey-metrics__item {
  display: inline-block;
  margin: 0 4%;
  text-align: center;
  vertical-align: middle;

  .i {
    vertical-align: bottom;
  }

  .i-time {
    width: 2em;
    height: 1.6em;
  }
}

.journey-metrics--inline {
  display: block;
  text-align: center;
  margin: 0 auto;

  .journey-metrics__item__label {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }

  i {
    vertical-align: middle;
  }

  .i-distance {
    width: 1.2em;
    height: 1.3em;
    vertical-align: middle;
  }

  .i-speed {
    width: 1.2em;
  }

  .i-time {
    width: 1.4em;
    height: 1.5em;
  }
}

.journey-metrics__item__label--white {
  color: #fff;
}

.journey-metrics__item__label {
  display: block;
  margin-top: 5px;
  font-weight: bold;
}

.journey-links__link__distance {
  font-style: italic;
  font-size: 0.8rem;
  display: inline-block;
  width: 54px;
  text-align: right;
  padding-right: 15px;
}

.journey-links__link__details {
  display: inline-block;
  max-width: 80%;
  min-height: 60px;
  border-left: 2px solid $ms-green;
  vertical-align: middle;
  padding-left: 25px;
  position: relative;
  padding-bottom: 20px;

  &.yellow {
    border-color: $ms-yellow;
  }

  &.red {
    border-color: $ms-red;
  }
}

.journey-links__link__details:before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: $ms-green;
  border-radius: 100%;
  left: -5px;
  top: 0;
}

.journey-links__link__details.yellow:before {
  background: $ms-yellow;
}

.journey-links__link__details.red:before {
  background: $ms-red;
}

.journey-links__link:last-of-type .journey-links__link__details:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: $ms-green;
  border-radius: 100%;
  left: -5px;
  top: inherit;
  bottom: 0;
}

.journey-links__link__trend {
  font-size: 0.9rem;
  font-style: italic;
  margin-left: 20px;
  margin-top: 2px;
}

.journey-links__link__details h4 {
  margin-bottom: 10px;
  line-height: 16px;
}

@media (max-width: 480px) {
  .journey-metrics .journey-metrics__item {
    margin-bottom: 10px;
  }
}

/**
  Full page map overlay
*/

.section--map {
  margin-top: 64px;

  // Hack to fix wildcard selector in pattern library
  .hide-panel-btn {
    height: auto;
    width: auto;
  }

  .map-key .title {
    display: none;
  }
}

.traffic-info__header .breadcrumb a {
  font-size: 16px;
}

.traffic-info__body {
  padding: 2rem;
}

.list-view {
  overflow-x: visible;
  overflow-y: visible;
  transform: translateX(-100%);
  transition: transform 400ms ease;
  bottom: auto;

  .list-view__toggle .i-caret-left {
    display: none;
  }
}

.list-view.active {
  transform: translateX(0);

  .list-view__toggle .i-caret-right {
    display: none;
  }

  .list-view__toggle .i-caret-left {
    display: block;
  }
}

.modal-active .list-toggle-wrap {
  display: none;
}

.list-view__toggle {
  position: absolute;
  top: 1em;
  left: 100%;
  display: block;
  background-color: #004771;
  color: #fff;
  line-height: 1;
  padding: 20px 8px;
  border-radius: 0 3px 3px 0;
  opacity: 1 !important;
}

.section--map .panel--nested {
  height: auto;
  margin-top: 1em;
  max-height: calc(100% - 2em);
  background: none;

  &.active {
    transform: translateX(1em);
  }
}

.map-key ul {
  padding: 0;
  margin: 0;
}

.map-key li {
  display: block;
  margin-bottom: 10px;
  list-style: none;
}

.map-key li:after {
  content: '';
  display: table;
  clear: both;
}

.map-key input {
  float: left;
  margin: 5px 10px 0 0;
  outline: none !important;
}

.map-key label {
  float: left;
}

.map-key label img {
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: auto;
  margin-right: 5px;
}

.map-key label,
.map-key label span {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1;
}

.map-key .journey-indicator {
  margin-right: 5px;
}

.leaflet-popup-content-wrapper {
  border-radius: 3px;

  h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0;

    img {
      display: inline-block;
      vertical-align: text-bottom;
      height: 14px !important;
      width: auto;
      margin: 0 5px 0 0 !important;
    }
  }
}

.ev-map-sidebar-icon {
  width: 20px;
}

.block-theme--chargers .theme__background {
  background-color: #0d7553;
  color: #fff;
}

.chargers-list-connectors {
  width: 175px;
}

.chargers-list-address {
  width: 25%;
}

.traffic-info-menu-region .theme--info a {
  text-decoration: underline;
}

#mobile-route-ev a,
.ev-map-link a {
  color: #fff;
  text-decoration: underline;
}

.delay-map .leaflet-clickable {
  cursor: initial;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

@media (max-width: 599px) {
  .section--map {
    #sidebar-region {
      height: auto;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      z-index: 99;
      min-height: 54px;
      text-align: center;
    }

    .list-view {
      position: static;
      transform: none;
      display: block;
      padding: 10px 5px;
      box-shadow: none;
      width: 100%;

      h1 {
        font-size: 18px;
        font-weight: bold;
        color: #005485;
        padding: 0;
        line-height: 1.2;
        margin-bottom: 5px;
      }
    }

    .list-view__toggle,
    .list-view .breadcrumb,
    .list-view .map-key-right,
    .map-key .title {
      display: none;
    }

    .traffic-info__header {
      background: none;
      padding: 0;
    }

    .traffic-info__body {
      padding: 0;
    }

    .map-key li {
      display: inline-block;
      margin: 0 10px;
      font-size: 12px;
    }

    .map-key label span {
      font-size: 14px;
      line-height: 14px;
    }

    .map-key label span:before {
      margin-right: 5px;
    }

    .panel--nested {
      margin: 0;
      width: 100%;
      height: 100%;

      &.active {
        transform: translateX(0);
      }
    }
  }
}

@media (max-width: 699px) {
  .section--map {
    .leaflet-control-geocoder-form input {
      width: auto;
    }

    .pac-search-box {
      width: auto;
    }
  }
}

@media (max-width: 400px) {
  .section--map {
    .leaflet-right .leaflet-control {
      clear: both;
    }

    .pac-search-box {
      width: 40%;
    }
  }
}

@media (min-width: 600px) {
  .section--map {
    margin-top: 80px;
  }
}
