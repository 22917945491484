@use '../../0-settings/settings' as s;
@use '../../1-mixins/container';
@use '../../1-mixins/breakpoints';

.c-banner {
  flex: 0 0 auto;
  color: s.$color--white;
  &.hidden {
    display: none;
  }
}

.c-banner__wrapper {
  @include container.container();
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  font-size: s.$font-size--300;
  padding-left: s.$size--400;
  padding-right: s.$size--400;
  @include breakpoints.tablet-landscape-up {
    align-items: center;
    flex-direction: row;
    padding-left: s.$size--500;
    padding-right: s.$size--500;
  }
  a {
    color: s.$color--white;
  }
  .external {
    color: white;
  }
}

.c-banner__content {
  flex: 1;
  width: 100%;
}

.c-banner--theme-error {
  background: s.$color--error;
}

.c-banner--theme-confirmation {
  background: s.$color--success;
}

.c-banner__dismiss-button.c-btn {
  flex: 0 0 auto;
  margin-left: s.$size--650;
  color: s.$color--white;
  &:hover {
    color: s.$color--white;
  }
}

.inner-page-banner {
  position: absolute;
  width: calc(100% - 48px);
  z-index: 10000;
  .inner-page-banner__link {
    background-image: none;
  }
}

.inner-page-banner__message {
  font-size: 1.4rem;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  padding-left: 18px;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  svg {
    margin-right: 10px;
  }
}

.inner-page-banner__message-text {
  display: inline-block;
  line-height: 1.4;
  vertical-align: middle;
  color: white;
  width: calc(100% - 100px);
}

.inner-page-banner__icon {
  display: inline-block;
  font-size: 2.5rem;
  margin-right: 10px;
}

@media screen and (max-width: 599px) {
}

.theme--error {
  .inner-page-banner__message {
    background-color: s.$color--error;
    color: white;
    a {
      color: white;
    }
  }
}
.theme--confirmation {
  .inner-page-banner__message {
    background-color: s.$color--success;
    color: white;
  }
}

/**
  Animate element in on page load:
*/

.banner-text-animation {
  animation-name: fadeInbottom;
  animation-duration: 600ms;
  animation-fill-mode: both;
  animation-delay: 100ms;
}

.banner-text-animation--out {
  animation-name: fadeOutbottom;
  animation-duration: 600ms;
  animation-fill-mode: both;
  animation-delay: 100ms;
}

@keyframes fadeInbottom {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutbottom {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
}
