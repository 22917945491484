// -----------------------------------------------------------------------------
// action links with icon
// -----------------------------------------------------------------------------
@use 'sass:math';

$height-circle-large: 72;
$height-icon-large: 4em;

$height-circle-small: 48;
$height-icon-small: 3em;

$height-circle-tiny: 40;
$height-icon-tiny: 2.5em;


.action__icon {
    @extend %round;
    @include rem(width, $height-circle-large);
    @include rem(height, $height-circle-large);
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    background-color: $base-link-color;
    @include up-to(small) {
        @include rem(width, $height-circle-small);
        @include rem(height, $height-circle-small);
    }

}

.action__icon .i {
    //@include rem(width, 48);
    //@include rem(height, 48);
    background-position: 0 $icon-bg-white;
    margin-top: 0;
    @include medium {
        @include rem(margin-top, 6);
    }
}



.action {
    @extend %link-hover-color;
    @extend %pt-m;
    text-align: center;
    display: block;

    & > span {
        
        @extend %mt-xs;
        @extend %font-size-s;

        display: block;
        @include medium {
            @include rem(max-width,144);
        }
        margin-left: auto;
        margin-right: auto;
    }

    & i {
            width: $height-icon-large;
            height: $height-icon-large;
    }
    @include up-to(small) {
        text-align: left;

        & > div,
        & > span {
            vertical-align: middle;
        }


        & i {
            background-position: 0 $icon-bg-white;
            margin-top: math.div(( $height-circle-small - $height-icon-small ) , 2);
            width: $height-icon-small;
            height: $height-icon-small;
        }
        & > span {
            padding-left: 1em;
            margin-top: 0;
            display: inline-block;
            width: 66%;
        }

    }
}

.action--small {
   .action__icon {
        @include rem(width, $height-circle-small);
        @include rem(height, $height-circle-small);
   }
   & .i {
        margin-top:0;
        width: $height-icon-small;
        height: $height-icon-small;
    }
}


.action--tiny {
   .action__icon {
        @include rem(width, $height-circle-tiny);
        @include rem(height, $height-circle-tiny);
   }
   & .i {
        margin-top:0;
        width: $height-icon-tiny;
        height: $height-icon-tiny;
    }
}

$tiny-icon: $height-circle-small + 10;
$small-icon: $height-circle-small + 15;
$large-icon: $height-circle-large + 20;

.action--horizontal {
    text-align: left;

        & > div,
        & > span {
            vertical-align: middle;
        }


        & i {
            background-position: 0 $icon-bg-white;
            margin-top: math.div(( $height-circle-small - $height-icon-small ) , 2);
            width: $height-icon-small;
            height: $height-icon-small;
        }
        & > span {
            padding-left: 1em;
            
            margin-top: 0;
            display: inline-block;
            width: 66%;
        }

        &.action--tiny > span {
            padding-left: 0.5em;
        }
}

.action--bold span {
    @extend %font-weight-bold; 
}

.action--green {
    .action__icon {
        background-color: #afc83d;
    }
}

.action--dark {

    .action__icon {
        background-color: #004771;
    }
}

.action--orange {

    .action__icon {
        background-color: #de3f00;
    }
}