@use '../../0-settings/settings' as s;

.c-heading--bold {
  font-weight: s.$weight--bold;
}

.c-heading--regular {
  font-weight: s.$weight--regular;
}

.c-heading--no-margin {
  margin: 0;
}
