@use '../0-settings/settings' as s;
@use '../1-mixins/breakpoints';

.l-weather-widget {
  @include breakpoints.phone-only {
    border: none;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    pointer-events: none;
  }

  @include breakpoints.tablet-landscape-up {
    display: block;
    position: absolute;
    top: s.$size--300;
    right: 0;
    border: none;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    pointer-events: none;
  }
}

.l-weather-widget-wrapper {
  margin: auto;
  padding: 20px;
}
