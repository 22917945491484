@use '../../0-settings/settings' as s;

// Public vars
$element-vertical-spacing: s.$size--100;

.c-form-field {
  margin-bottom: s.$size--400;
}

.c-form-field__labels {
  display: flex;
  align-items: flex-end;
  margin-bottom: $element-vertical-spacing;
}

.c-form-field__label {
  font-size: s.$font-size--100;
  font-weight: s.$weight--semi;
}

.c-form-field__required-text {
  font-size: s.$font-size--50;
  font-weight: s.$weight--semi;
  color: s.$color--error;
  margin-left: s.$size--200;
  margin-bottom: s.$size--400;

  &::selection {
    background-color: s.$color--error;
  }
}

.c-form-field__input {
  margin-bottom: $element-vertical-spacing;
}

.c-form-field__help-text {
  font-size: s.$font-size--100;
  line-height: 1.5;
  color: s.$color--gray-standard;
}

.c-form-field__validation-msg {
  font-size: s.$font-size--100;
  line-height: 1.5;
  color: s.$color--error;

  &::selection {
    background-color: s.$color--error;
  }
}
.c-form-field--group {
  display: flex;
  .c-form-field {
    margin-right: 24px;
    width: 100%;
    max-width: 128px;
  }
}
