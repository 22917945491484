
.anchor-links {
    @extend %pt-xxxl;
    @extend %pb-xxxl;
    @extend %pl-m;
    @extend %pr-m;
    @extend %mb-m;
    @extend %list-unstyled;
    background-color:#f4f8fb;
    
    li {
        @extend %mt-0;
        @extend %relative;
        &:before, 
        &:after {
            @extend %absolute;
            @extend %width-0;
            @extend %height-0;
            @include rem(top, $space-s);
        }
        &:before {
            @extend %left-0;
            content: '';
            border: 4px solid transparent;
            border-top: 4px solid $dark-grey;
        }
        &:after {
            content: '';
            left: 2px;
            border: 2px solid transparent;
            border-top: 2px solid $ultra-light-grey;
        }
        
        @include media('small-down') {
            a {
                @include rem(padding-left, 10);
            }
            
        }
       
    }
    .grid {
        margin:0;
    }
}
