@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-map-filter {
  @include breakpoints.tablet-portrait-up {
    padding-top: s.$size--500;
  }
}

.c-map-filter__list {
  list-style-type: none;
  padding: 0 20px;
  margin: 0;
  margin-bottom: 12px;
}

.c-map-filter__traffic-flow {
  padding: 20px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.c-map-filter__traffic-flow-heading {
  display: flex;
  justify-content: space-between;
}

.c-map-filter__item {
  display: flex;
  align-items: center;
}

.c-map-filter__icon {
  flex: 0 0 32px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-map-filter__label {
  border-top: 1px solid black;
  width: 100%;
}

.c-map-filter__label .c-checkable-field {
  align-items: center;
}

.c-map-filter__label .c-checkable-field__label {
  flex: 1;
  order: 1;
  height: 40px;
  display: flex;
  align-items: center;
}

.c-map-filter__label .c-checkable-field__input {
  order: 2;
}
