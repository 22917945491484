@use '../0-settings/settings' as s;
@use '../1-mixins/breakpoints';
@use '../5-components/navigation/header';

// Public vars
$pop-out-panel-width: 420px;
$side-panel-width: 320px;

// Private vars
$_shadow: s.$shadow--200;

.l-map-tool__container {
  flex-direction: column;
  display: flex;
  height: 100%;
  flex: 1;
  position: relative;

  @include breakpoints.tablet-portrait-up {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex: 1 1 auto;
  }
}

.l-map-tool,
.l-map-tool__wrapper {
  flex-direction: column;
  display: flex;
  height: 100%;
  flex: 1;
  position: relative;

  @include breakpoints.tablet-landscape-up {
    display: block;
  }
}

.l-map-tool__wrapper {
  height: 100%;
}

.l-map-tool {
  flex: 1;
  max-height: 100%;
  height: 100%;

  @include breakpoints.tablet-portrait-up {
    flex-direction: column;
  }
}

.l-map-tool__sidebar {
  position: relative;
  display: flex;
  z-index: s.$z--sidebar;
  flex: 1;
  background-color: s.$color--white;
  box-shadow: $_shadow;

  @include breakpoints.tablet-portrait-up {
    display: flex;
    flex: 0 1 $side-panel-width;
  }
}

.l-map-tool--activeSearch .l-map-tool__sidebar,
.l-map-tool--selected-search .l-map-tool__sidebar {
  flex: 0 0 auto;
  @include breakpoints.tablet-portrait-up {
    flex: 0 1 $side-panel-width;
  }
}

.l-map-tool__map-area {
  flex: 1;
  display: none;
  flex-direction: column;
  overflow: hidden;

  @include breakpoints.tablet-portrait-up {
    display: flex;
    flex: 1;
  }
}

.l-map-tool--map-view-active.l-map-tool--activeSearch .l-map-tool__map-area {
  display: flex;
}

.l-map-tool--map-view-active .l-map-tool__map-area--activeSearch {
  display: flex;
}

.l-map-tool__map-area.l-map-tool__map-area--show-on-mobile {
  display: flex;
}

.l-map-tool__map-area.l-map-tool__map-area--show-on-mobile ~ .l-map-tool__sidebar {
  flex: 0;
}

.l-map-tool__wrapper.l-map-tool--list-view-active.l-map-tool--activeSearch {
  .l-map-tool__sidebar {
    flex: 1;
    @include breakpoints.tablet-portrait-up {
      flex: 0 1 $side-panel-width;
    }
  }
}

.l-map-tool__map-container {
  width: 100%;
  height: 100%;
  flex: 1;
}

.l-map-tool--map-area-fullscreen .l-map-tool__sidebar {
  height: 0;
  @include breakpoints.tablet-portrait-up {
    height: auto;
  }
}
