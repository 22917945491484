@use '../0-settings/settings' as s;
@use '../1-mixins/breakpoints';

.l-traffic-dashboard {
  max-width: s.$size--1500;
}

.l-traffic-dashboard__divider {
  border-top: 1px solid s.$color--gray-lines;
}

.l-traffic-dashboard__journey-group {
  margin-top: 40px;
  margin-bottom: 40px;
}

.l-traffic-dashboard__group-heading {
  margin-bottom: 16px;
}

.l-traffic-dashboard__free-flowing,
.l-traffic-dashboard__current {
  width: s.$size--1000;
}

.l-traffic-dashboard .l-traffic-dashboard__route {
  padding-right: 40px;
}

.l-traffic-dashboard__traffic-segments {
  margin: 24px 0;
}

.l-traffic-dashboard__footer.c-pop-out-panel__footer {
  margin-top: 12px;
  display: flex;

  @include breakpoints.tablet-portrait-up {
    display: none;
  }
}

.l-traffic-dashboard__metrics-list {
  display: flex;
  flex-direction: column;

  @include breakpoints.tablet-portrait-up {
    flex-direction: row;
  }
}

.l-traffic-dashboard__metric {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  @include breakpoints.tablet-portrait-up {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }
}

.l-traffic-dashboard__metric .c-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;

  @include breakpoints.tablet-portrait-up {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
  }
}

.l-traffic-dashboard__metric-label {
  flex: 0 0 120px;

  @include breakpoints.tablet-portrait-up {
    flex: none;
  }
}