@use '../1-mixins/breakpoints';
@use '../1-mixins/container';

.header-row{
    margin-top: 40px;
}

.login-row{
    margin: 16px 0;
}
.login-col{
    @include breakpoints.ipad-portrait-up {
        border-right: 1px solid #000000;
    }
}

.hide{
    display:none;
}

#show-register-button, #show-login--button{
    min-width: 35%;
    @include breakpoints.ipad-portrait-down {
        display:none;
    }
}

#show-register-button--mobile, #show-login-button--mobile{
    min-width: 35%;
    background: transparent;
    width: 100%;
    border: none;
    color: #2575AE;
    text-decoration: underline;
    @include breakpoints.ipad-portrait-up {
        display:none;

    }
}

.message.bad, .message.error, .message.required, .message.validation{
    margin-top: 0;
    margin-bottom: 1rem;
    display: inline-block;
    color: #db0000;
}

input.action{
    width: 100%;
    min-width: 35%;
    color: #fff;
    font-family: inherit;
    padding: 12px;
    margin-bottom: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 40px;
    padding: 0 16px;
    border: none;
    border-radius: 3px;
    box-shadow: 3px 3px 10px rgb(67 51 122 / 30%);
    font-weight: 600;
    font-size: 1.6rem;
    text-align: center;
    line-height: 1;
    text-decoration: none;
    transition: background-color .4s cubic-bezier(.22,1,.36,1),color .4s cubic-bezier(.22,1,.36,1);
    cursor: pointer;
    background-color: #2575ae;
    &:hover{
        background-color: #00456b;
        color: #fff;
    }
    @include breakpoints.ipad-portrait-up {
    width: unset;
    }
}


fieldset{
    border:none;
    padding:0;
    padding-bottom: 24px;
}



input.text{
    border-radius: 4px;
    border-radius: 0.4rem;
    -webkit-appearance: none;
    border: 1px solid #00456b;
    background-color: white;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    width: 100%;
    padding: 8px 12px;
    height: 36px;
    margin-bottom: 8px;
    @include breakpoints.ipad-portrait-up {
        max-width: 280px;
    }
}
#MemberLoginForm_LoginForm_Remember_Holder{
    margin-top: 25px;
}
.field label.left{
        font-size: 1.2rem;
        font-weight: 600;
}


#Form_RegisterForm_TermsAgreement_Holder{
    padding: 18px 20px 16px;
    background-color:  #F7F9E9;
    @include breakpoints.ipad-portrait-down {
        margin-left: -48px;
        padding-left: 48px;
        padding-right: 48px;
        width: calc(100% + 96px);
    }
}

#Form_RegisterForm_action_register{
    margin-bottom: 2rem;
}

.terms-and-conditions{
    background-color:  #F7F9E9;
    padding: 16px 20px 0;
    @include breakpoints.ipad-portrait-down {
        margin-left: -48px;
        padding-left: 48px;
        padding-right: 48px;
        width: calc(100% + 96px);
    }
    p{
        margin-top: 0;
    }
    .sub-text{
        padding-left: 18px;
        margin-bottom: 0;
        font-size: 12px;
        li:not(:last-child){
            padding-bottom: 20px;

        }
    }
}

#Form_RegisterForm{
    .field.text input, .text.password input{
        margin-bottom: 16px;
    }
}
@include breakpoints.ipad-portrait-up {
    #Form_RegisterForm_Email_Holder{
        max-width: 49%;
    }
    #Form_RegisterForm_FirstName_Holder,
    #Form_RegisterForm_Surname_Holder
    {

        display: inline-flex;
        flex:1;
        flex-direction: column;
        max-width: 49%;

    }
    #Form_RegisterForm_Surname_Holder{
        margin-left: 1%;
    }
    #Form_RegisterForm_Password_Holder .middleColumn{
        display:flex;
        justify-content: space-between;

        #Password_Password_Holder,
        #Password_ConfirmPassword_Holder{
            flex:1;
            max-width: 49%;
        }
    }
}

#SAMLLoginForm_LoginForm_Tab {
  border-bottom: 1px dotted #000000;
  padding-bottom: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
