/*------------------------------------*\
    #FIELD DROPDOWN
\*------------------------------------*/

.field--dropdown {
  margin-bottom: 5px;

  select {
    cursor: pointer;
    height: 40px !important; // important to override customSelect bug that cant find customSelectSpan.outerHeight() properly

    &:disabled {
      cursor: not-allowed;
    }
  }

  .dropdown {
    background-color: #f2f2eb;
    background-repeat: no-repeat;
    background-position: 95% 0;
    // background-image: url("/resources/themes/default/dist/images/forms/field--dropdown.png");
    background-size: 20px 160px;
    color: inherit;
    border-radius: 0.25rem;
    width: 100%;
    line-height: 1;
    height: 40px;

    .dropdownInner {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 10% 0 1rem;
      line-height: 40px;
    }

    /* select box is open */
    &.dropdownOpen {
      background-position: 95% -40px;
    }

    /* select box is in focus */
    &.dropdownFocus {
      outline: solid 3px #ff5c00;
    }

    /* dark blue theme */
    &.dropdown--dark {
      background-color: #094771;
      background-position: 95% -80px;
      color: #fff;
      &.dropdownOpen {
        background-position: 95% -120px;
      }
    }

    /* select box itself is disabled */
    &.dropdownDisabled {
      background: #eee;
      cursor: not-allowed;
      color: inherit;
      opacity: 0.6;
    }
  }
}
