@use '../../0-settings/settings.scss' as s;

.c-panel-header--inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-panel-header--inline .c-panel-header__heading {
  font-size: s.$font-size--300;
  flex: 1;
  margin-bottom: 0;
}

.c-panel-header--inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-panel-header--inline .c-panel-header__heading {
  font-size: s.$font-size--300;
  flex: 1;
  margin-bottom: 0;
}

.c-panel-header__actions {
  display: flex;
  justify-content: flex-end;
}

.c-panel-header__heading {
  display: inline-flex;
  position: relative;
  padding-right: 40px;
  span:focus-visible{
    outline: unset;
  }
}

.c-panel-header__info {
  top: -8px;
  right: 0;
  position: absolute;
}

.c-panel-header--no-heading-margin .c-panel-header__heading {
  margin-bottom: 0;
}
