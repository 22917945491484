@use '../0-settings/settings' as s;
@use '../1-mixins/breakpoints';
@use '../5-components/navigation/header' as header;
@use '../4-layout/map-tool' as map-tool;
@use '../1-mixins/container';

.l-container{
  width: 100%;

  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @include breakpoints.tablet-landscape-up {
      @include container.container;
  }
}



.l-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;

  @include breakpoints.tablet-landscape-down {
    height: auto;
    min-height: auto;
    overflow-x: unset;
  }
}

.l-page__header {
  flex: 0 1 auto;
}

.l-page__content {
  display: flex;
  flex-wrap: nowrap;
  flex: 1 0 auto;

  > * {
    flex: 1 1 100%;
  }

  @include breakpoints.tablet-landscape-down {
    min-height: 100vh;
  }

  .nonvisual-indicator {
    display: none;
  }
}

.l-page__footer {
  flex: 0 1 auto;
}

// Content page specific
.content-page {
  padding-left: 4%;
  padding-right: 4%;

  img {
    max-width: 100%;
    height: auto;
  }

  iframe {
    max-width: 100%;
  }

  .ss-htmleditorfield-file{
    max-width: 100%;
  }
}

// Region content page specific
.region-content-page {
  padding-left: 4%;
  padding-right: 4%;

  img {
    max-width: 100%;
    height: auto;
  }

  iframe {
    max-width: 100%;
  }

  .ss-htmleditorfield-file{
    max-width: 100%;
  }

  // RegionContentPage Specific:
  /**
    File buttons / tiles:
  */
  .btn--primary {
    background-color: s.$color--jellybean;
    color: s.$color--white;
    border: s.$color--jellybean;
  }

  .file-btn {
    position: relative;
    display: block;
    padding: s.$size--400 s.$size--625 s.$size--400 s.$size--400;
    border-radius: 0.25rem;
    margin: 0 0 s.$size--450;
    overflow: hidden;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    font-weight: s.$weight--regular;
    a {
      color: s.$color--white;
    }

    @include breakpoints.tablet-portrait-up {
      display: inline-block;
      min-height: 100px;
      vertical-align: top;
      width: calc(33% - 20px);
      min-height: s.$size--900;
      margin: 0 s.$size--450 s.$size--450 0;
      padding-right: s.$size--400;
      padding-bottom: s.$size--600;
    }

    @include breakpoints.tablet-landscape-up {
      padding-right: s.$size--500;
      padding-bottom: s.$size--400;
    }
  }

  .file-btn--blue {
    border-color: s.$color--cerulean;
  }

  .file-btn--red {
    border-color: s.$color--well-read;
  }

  .file-btn--green {
    border-color: s.$color--fruit-salad;
  }

  .file-btn--yellow {
    border-color: s.$color--tulip-tree;
  }

  .file-btn--orange {
    border-color: s.$color--grenadier;
  }

  .file-btn--light-blue {
    border-color: s.$color--cerulean;
  }

  .journey-group-list {
    th {
      background: none;
      border-bottom: 1px solid s.$color--gray-light;
    }

    td {
      border: none;
      padding: 16px 0;
      position: relative;
    }

    .journey-indicator-col {
      text-align: center;
      padding-right: 0;
      padding-left: 0;
      vertical-align: top;
    }

    .journey-name {
      width: 50%;
    }

    .journey-cost {
      color: s.$color--mid-grey;
    }
  }

  .journey-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;

    &.red {
      background: s.$color--indicator-red;
    }

    &.yellow {
      background: s.$color--indicator-yellow;
    }

    &.green {
      background: s.$color--indicator-green;
    }
  }

  .journey-group-list__first-direction td {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .journey-group-list__second-direction td {
    padding-top: 0;
  }

  .journey-colour-key {
    margin-top: 40px;
  }

  .journey-colour-key__item {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }

  @media (max-width: 600px) {
    .journey-group-list td,
    .journey-group-list th {
      font-size: 12px;
      line-height: 1.2;
      padding: 5px 5px 5px 0;
    }
  }
}


// Journey planner page specific

.journey-planner {
  .l-page__content {
    flex: 1;
    flex-direction: column;
    @include breakpoints.tablet-portrait-up {
      overflow: hidden;
    }
  }
}

.l-page__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  @include breakpoints.tablet-portrait-up {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex: 1 1 auto;
    flex-direction: row;
  }
}

.l-page__wrapper {
  overflow: auto;
}

.l-page__sidebar {
  position: relative;
  display: flex;
  z-index: s.$z--sidebar;
  flex: 0 0 auto;
  box-shadow: s.$shadow--200;

  @include breakpoints.tablet-portrait-up {
    display: flex;
    flex: 0 1 map-tool.$side-panel-width;
  }
}

.l-page__main-content {
  flex: 1;
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;

  h1 {
    font-size: 30px;
    font-weight: 400;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  a {
    text-decoration: underline;
  }
}
