@use '../../0-settings/settings' as s;

.c-detail-page {
  max-width: s.$size--1600;
}

.c-detail__map {
  margin-bottom: s.$size--600;
}

.delay__main-heading {
  line-height: 1;
  margin-bottom: s.$size--600;
  margin-top: 0;
  font-size: s.$font-size--700;
}

.delay__main-heading--highlight {
  color: s.$color--warning;
}

.l-map-tool__map-container {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.delay-sub-heading {
  margin-bottom: s.$size--600;
  display: flex;
  flex-direction: row;
}

.delay-sub-heading__icon {}

.delay-sub-heading__text-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: s.$size--300;
}

.delay-sub-heading__title {
  font-size: s.$font-size--600;
  margin: 0 0 s.$size--200;
  line-height: 1;
  font-weight: s.$weight--regular;
}

.delay-sub-heading__title--highlight {
  font-weight: s.$weight--semi;
}

.delay-sub-heading__text {
  font-size: s.$font-size--400;
  margin: 0;
  line-height: 1;
  font-weight: s.$weight--regular;
}

.detail-event-comments {
  margin-bottom: 30px;
}

.detail-list {
  border-top: 1px solid s.$color--gray-lines;
  border-bottom: 1px solid s.$color--gray-lines;
  padding: s.$size--450 0;
  margin: 0 0 40px;
  overflow: hidden;

  dd,
  dt {
    font-size: s.$font-size--200;
    line-height: s.$line-height--large;
    float: left;
    padding: 0;
    margin: 0 0 10px;
    border: none;
  }

  dt {
    width: 40%;
    clear: left;
    font-weight: s.$weight--semi;
  }

  dd {
    width: 60%;
    text-align: left;
  }

  .def-list__big-item {
    font-size: 20px;
  }
}

#traffic-update-content .ss-htmleditorfield-file {
  max-width: 100%;
}