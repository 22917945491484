$popupWidth: 550px;

.hotspot-map-overlay {
    position: absolute;
    left: 0;
    margin-top: 1em;
    background-color: $white;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.4);
    overflow-x: hidden;
    overflow-y: auto;
}
.list-active .hotspot-map-overlay {
    position: absolute;

    @include up-to(small) {
        box-shadow: none;
    }
}

.map-controls.hotspot-map-controls {
  @include up-to(small) {
    margin-top: 2em;
  }
}

#hotspot-sidebar {
    transition: transform 500ms ease;
    transform: translateX(0);
    top: unset;
    @include up-to(small) {
        width: 40%;

        .hotspot-header h1 {
            margin-top: 2.1em;
        }
    }

    &.hide-panel {
        transform: translateX(-550px);
    }
}

.list-active #hotspot-sidebar {
    @include up-to(small) {
        display: block;
        width: 100%;
        min-height: unset;
    }
}

#hotspot-popup {
    max-width: $popupWidth;
    z-index: 1;
    transition: transform 500ms ease;
    transform: translateX(-$breakpoint-small-max);

    &.show-panel {
        transform: translateX(0);
        max-width: 650px;
    }

    @include up-to(small) {
        margin-bottom: 0;

        &.show-panel {
            width: 100%;
            max-width: none;
        }
    }
}

.old-ie {
    #hotspot-popup {
        display: none;

        &.show-panel {
            display: block;
        }
    }

    #hotspot-sidebar {
        display: block;

        &.hide-panel {
            display: none;
        }
    }
}

.hotspot-header {
    @extend %theme--dark;
    padding: 10px;
    border-top-right-radius: 2px;

    @include up-to(small) {
        border-radius: 0;
    }
}
.hotspot-header h2 {
    margin: 10px 15px;
}
.hotspot-close-popup {
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
    margin: 15px;
    @include up-to(small) {
        display: none;
    }
}

.hotspot-content {
  padding: 20px;

  &.locations {
    padding: 0;
  }
}

.hotspot-location-group {
    padding: 20px;
    border-bottom: 1px solid #eee;

    h3 {
        margin: 0;
        padding: 0;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        margin: 0;
    }
}

.hotspot-content li {
    list-style: none;
}
.hotspot-footer {
    padding: 15px 50px;
    text-align: center;
}

.hotspot-key {
  display: inline-block;
}
.hotspot-list-switch {
    display: inline-block;
    vertical-align: middle;
    margin-right: 50px;
}
.hotspot-list-switch label {
    display: inline;
    color: #555;
    padding-left: 10px;
}
.hotspot-list-switch input {
    margin: 0 0 0 20px;
}

.hotspot-key-colour {
    padding: 0px 5px;
}
.hotspot-key-colour.busy {
    border-left: solid 20px #ffa100;
}
.hotspot-key-colour.heaviest {
    border-left: solid 20px #cc2828;
}

.hotspot-key-colour.free-flowing {
    border-left: solid 20px #afc83d;
}

.hotspot-heaviest {
    display: block;
    font-weight: bold;
}
.hotspot-direction {
    color: #236FA6;
    padding-top: 15px;
}

.hotspot-chart {
  margin-bottom: 20px;
}

.hotspot-row {
    height: 20px;
    width: 100%;
}
.hotspots-date {
    width: 40px;
    padding: 5px;
}
.hotspots-box-wrap {
    height: 100%;
    position: relative;
}
.hotspots-box {
    position: absolute;
    top: 0;
    bottom: 0;
}
.sep {
    border-left: 1px solid #000;
}
.hotspot-listing table, .hotspot-listing td {
    border: none;
}

rect.normal {
    fill: #afc83d;
}
rect.busy {
    fill: #ffa100;
}
rect.heaviest {
    fill: #cc2828;
}
.tick line {
    fill: none;
    stroke: rgba(0,0,0,0.1);
    shape-rendering: crispEdges;
    pointer-events: none;
}
.tick line.highlight {
    stroke: rgba(0,0,0,0.25);
}
.HolidayHotspotsPage {
    .leaflet-popup-content-wrapper {
        @extend %theme--dark;
        font-weight: bold;
        border-radius: 2px;
    }
    .leaflet-popup-content {
        line-height: 1;
        margin: 5px 15px;
    }
    .leaflet-popup-close-button {
        display: none;
    }
    .leaflet-popup-tip {
        @extend %theme--dark;
    }
    .leaflet-marker-icon {
        cursor: pointer;
    }
}

.hotspot-tooltip {
    @extend %theme--dark;
    line-height: 1;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 2px;
    z-index: 3;
    font-size: 0.8rem;
}
.hotspot-tooltip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: $dark-bg-color;
    content: "\25BC";
    position: absolute;
    text-align: center;
    /** Ideally these would only be specified on directions e.g. ".n" below */
    /** But that makes the page scroll jump on mobile when tooltip opens */
    top: 100%;
    left: 0;
}
.hotspot-tooltip.n:after {
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
}
.hotspot-chart text {
    fill: #666;
    font-size: 0.8rem;
}

input[type="radio"]:focus {
    outline: none;
}

.hotspot-listing td:first-child {
    padding-left: 0;
    width: 115px;
}

@include up-to(small) {
    .hotspot-key {
        margin: 15px 0;
        display: block;
        width: 100%;

        &:before {
            content: 'Key: ';
            margin-right: 20px;
        }
    }

    .hotspot-key-text {
        display: inline-block;
        min-width: 60px;
        margin-right: 20px;
    }

    .hotspot-list-switch input {
        margin: 0 10px 0 0;
    }

    .hotspot-list-switch label {
        margin-right: 25px;
    }

    .hotspot-key-colour {
        display: inline-block;
        padding: 0 10px;
        border-width: 10px !important;
        font-size: 12px;
    }

    .hotspot-listing td {
        padding: 10px;
    }

    .hotspot-listing td:first-child {
        font-size: 12px;
        line-height: 1.6;
        width: 70px;
        color: #236FA6;
        text-transform: uppercase;
        vertical-align: top;
    }

    .hotspot-listing td:last-child {
        font-size: 14px;
        line-height: 1.5;
    }

    .hotspot-key-colour {
        font-size: 14px;
        padding: 0px 5px 0 7px;
        margin-left: 10px;
    }
}

@media print {
    .hotspot-close-popup,
    .hotspot-list-switch,
    .section--map,
    .section--map-nav {
        display: none;
    }

    #hotspot-popup, #hotspot-sidebar {
        border: none;
        box-shadow: none;
    }
}
