@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-journey-form__heading {
  @include breakpoints.phone-only {
    display: none;
  }
}

.c-journey-form__fields {
  display: flex;
  justify-content: space-between;
}

.c-journey-form__inputs {
  flex: 1 1 auto;
  position: relative;
}

.c-journey-form__field-marker {
  display: none;
}

.c-journey-form--compact-mobile-view {
  .c-journey-form__inputs .c-form-field {
    flex: 1 1 auto;
    margin-bottom: s.$size--100;
  }

  .c-journey-form__field {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .c-journey-form__field-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: s.$size--200;
    margin-right: s.$size--200;
    position: relative;
    width: s.$size--500;
    height: s.$size--500;
    font-weight: s.$weight--bold;

    .c-icon {
      position: absolute;
      color: s.$color--primary;
    }

    @include breakpoints.tablet-portrait-up {
      display: none;
    }
  }

  @include breakpoints.phone-only {
    .c-panel-header--default{
      display:none;
    }
    .c-form-field__labels {
      display: none;
    }
  }
}

.c-journey-form__marker-label {
  z-index: 10;
  transform: translateY(-3px);
  font-size: s.$font-size--200;
  color: s.$color--white;
}

.c-journey-form__switch {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.c-journey-form__switch-btn {
  padding: s.$size--200 0 s.$size--200 s.$size--300;
  background: none;
  border: none;
  margin-bottom: s.$size--100;
  color: s.$color--primary;
  cursor: pointer;

  .c-icon {
    width: 18px;
    height: 18px;
  }
}

.c-journey-form__filters {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  @include breakpoints.tablet-portrait-up {
    margin: s.$size--400 0;
  }
}

.c-journey-form__submit-btn {
  @include breakpoints.phone-only {
    display: none;
  }
}
