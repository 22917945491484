/*
 * Global sizing/spacing scale
 *
 * Non-linear scale to use for spacing and sizing throughout project. Based on scale suggested in Refactoring UI book (pg 63)
 */

$_root-size: 16px !default;

$size--100: $_root-size * 0.25; // 4px
$size--200: $_root-size * 0.5; // 8px
$size--300: $_root-size * 0.75; // 12px
$size--400: $_root-size * 1; // 16px
$size--450: $_root-size * 1.25; // 20px
$size--500: $_root-size * 1.5; // 24px
$size--600: $_root-size * 2; // 32px
$size--625: $_root-size * 2.25; // 36px
$size--650: $_root-size * 2.5; // 40px
$size--675: $_root-size * 2.75; // 44px (special use case as min touch area)
$size--700: $_root-size * 3; // 48px
$size--800: $_root-size * 4; // 64px
$size--850: $_root-size * 5; // 80px
$size--900: $_root-size * 6; // 96px
$size--1000: $_root-size * 8; // 128px
$size--1100: $_root-size * 12; // 192px
$size--1200: $_root-size * 16; // 256px
$size--1300: $_root-size * 24; // 384px
$size--1400: $_root-size * 32; // 512px
$size--1500: $_root-size * 40; // 640px
$size--1600: $_root-size * 48; // 768px
$size--1700: $_root-size * 64; // 1024px

/**
 * Common size related aliases
 */

// Min width content container settings
$container-max-width: $size--1700;
$default-gutter-width: $size--500;

// Consistent vertical spacing between components
$vertical-spacing--default: $size--700;
$vertical-spacing--default-mobile: $size--600;

// Navigation panel module padding
// (applying separately allows individual items to go to edge of panel)
$panel-item-padding--mobile: $size--450;
$panel-item-padding--desktop: $size--450;

// Panel sizes
$pop-out-panel-width: 420px;
$side-panel-width: 320px;

// Input width options
$input-width--tiny: $size--800;
$input-width--small: $size--900;
$input-width--medium: $size--1000;
$input-width--large: $size--1200;
$input-width--default: $size--1400;
