/*------------------------------------*\
    #RADIO BUTTON LIST
\*------------------------------------*/

.field--radio-button-list { @extend %field; }

    .radio-button-list {
        @extend %list-unstyled;
        @extend %bb--primary;

        & > li {
            @extend %mt-0;
            @extend %relative;
            @extend %bt--primary;
            @extend %clearfix;
        }
        input {
            @extend %absolute;
            @extend %left-0;
            top:32%;
        }

        label {
            @extend %pt-s;
            @extend %pr-0;
            @extend %pb-s;
            @extend %pl-xxxl;

            span {
                @extend %font-size-xs;
                @extend %block-element;
            }
        }
    }
