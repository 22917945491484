@use '../../0-settings/settings' as s;

.event-title{
    display:flex;
    justify-content: space-between;
}
.event-list {
    padding: 0;
    margin: 0 0 40px;
    list-style: none;
  }
  
  .news-list__item {
    
    list-style: none;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 10px;
  
    p {
      font-size: 0.9rem;
      margin-bottom: 0;
    }
  }
  
  .event-list__item {

    list-style: none;
    position: relative;
    padding-left: 28px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  
    p {
      font-size: 0.9rem;
      margin-bottom: 0;
    }
  }
  
  .event-list__item-icon {
    position: absolute;
    left: 0;
    top: 3px;
    background-size: 22px;
  }
  
  .event-list__item-heading {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 20px;
    a{
        color: s.$color--regal-blue;
        text-decoration:underline;
        &:hover{

            color: #2575ae;
        }
    }
  }

  .event-list__item--content {
    flex:1;
  }
  
  .event-list__item-sub-heading {
    margin-bottom: 10px;
  }
  
  .event-list__link-icon {
    position: absolute;
    bottom: 15px;
    right: 0;
  
    i {
      font-size: 1rem;
    }
  }
  
  .event-list__item--with-map {

    padding-top: 16px;
    padding-bottom: 24px;
    padding-left: 0;

    list-style: none;
    position: relative;
    display:flex;

    @media screen and (max-width: 599px) {
      flex-direction: column;
    }

    &:first-child{
        border-top: 1px solid s.$color--silver-chalice;
    }
    border-bottom: 1px solid s.$color--silver-chalice;
  
    .event-list__item-sub-heading {
      font-size: 1.2rem;
      color: #666;
    }
  }
  
  .event-list__icon,
  .event-list__map-image {
    display: block;
    width: 155px;
    height: 102px;
    float: left;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .event-list__map-image--medium {
    width: 100%;
    height: unset;
    img{
      width: 100%;
    }
    @media screen and (min-width: 599px) {
      width: 280px;

    }  
  }
  
  .event-list__icon.icon-only,
  .event-list__map-image.icon-only {
    width: 26px;
    height: 34px;
    margin-top: 8px;
  }
  
  .field--dropdown .incident-sort .dropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: initial;
  }
  
  .field--dropdown .incident-sort .dropdown--dark {
    background-position: 3% -80px;
    padding-left: 38px;
  }
  

  
  .event-list .default-hidden {
    display: none;
  }
  
  .event-list.active .default-hidden {
    display: inherit;
  }
