/**
 * GLOBAL NAV
 *
 * Contents:
 * 1. Nav Pills
 * 2. Global nav - blue header (default)
 * 3. Global nav - white header
 */


/*
* 1. Nav Pills
*/
%nav-pill {
    @extend %font-size-s;
    @include vendor(border-radius, .25rem);
    padding: 0.2em .6em;
}

.nav-pill {
    @extend %nav-pill;
}


/*
* 2. Global nav - blue header (default)
*/
.global-nav__items {
    & > * {
        @extend %right;
    }
}

.global-nav--home {
    & + * {
        margin-top: -5.5em;
        padding-top: 5.5em;
        background-color: #7196a8;
    }
}

.global-nav {
    @extend %pt-m;
    @extend %pb-m;
    @extend %pt-xl--q-medium;
    @extend %pb-xl--q-medium;
    @extend %relative;

    z-index: 11;

    .search-container {
        @extend %mr-m;
        @extend %relative;
        width: 55%;

        @include small-only {
            position: absolute;
            top: 100%;
            z-index: 10;
            margin-top: -1.5em;
            right: 1em;
            left: 1em;
            width: 80%;
        }
    }

    .i-menu, .i-menu:hover {
        opacity: .75;
    }

    .search__input,
    .menu {
        @include vendor(border-radius, .25rem);
        line-height: 25px;
        height: 30px;
    }

    .search__input {
        @extend %font-size-s;
        @extend %pl-s;
        background-color: $dark-blue;
        border-color: $dark-blue;
    }

    .search__icon {
        display: block;
        position: absolute;
        right: 1em;
        top: 9px;
        padding: 7px;

        &:hover {
            background-position: 0 $icon-bg-white;
        }
    }

    .menu {
        background-color: $med-blue;
        &:hover {
            background-color: $dark-blue;
        }
    }

    // mobile view, search icon
    .search-small {
        @extend %pr-l;
        .i-search {
            opacity: .75;
        }
    }

    /* mobile view, menu icon */
    .menu-small {
        @extend %pl-l;
        .i-menu {
           opacity: .75; //cancels the hover color on the icon, as the button behind should have the only hover effect
           padding: 10px; // enlarges the hamburger icon for mobile view, makes it same size as the search icon
        }
    }
}


/*
* 3. Global nav - white header (home page and traffic map page)
*/
.theme--full-colour {
    background-color: $white;

    a {
        color: $base-link-color;
    }

    .menu,
    .search__input {
        background-color: #f2f4f7;
    }

    .menu {
        &:hover {
            background-color: darken(#f2f4f7, 2%);
        }
    }

    .search__input {
        border-color: #f2f2eb;
        color: $dark-grey;
        &:focus {
            background-color: darken(#f2f4f7, 2%);
        }
    }

    .i-search,
    .i-menu {
        background-position: 0 $icon-bg-dark-blue;
    }

    .i-search {
        opacity: .75;
        &:hover {
            opacity: .5;
            background-position: 0 $icon-bg-dark-blue;
        }
    }

    .menu-small:hover {
        opacity: .65;
    }
}
