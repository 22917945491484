/*------------------------------------*\
    #ACCESSIBILITY
\*------------------------------------*/

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.access {
    width: 0;
    height: 0;
    position: absolute;
    overflow: hidden;
}

.access:focus,
.access-keys:target {
    width: auto;
    height: auto;
    position: static;
    overflow: visible;
    visibility: visible;
    width: auto;
    height: auto;
    overflow: visible;
}


.access-keys {
    background-color: rgb(252, 244, 185);
}


    .access-keys__key {
        margin-right: .5em;
        display: inline-block;
        width: 2.5em;
    }

// -----------------------------------------------------------------------------
// To indicate that a link goes off-site (to an external URL), include the
// class 'external' on your <a> tags. Also, consider including SR-friendly tags:
// <a class="external" href="http://google.com">Go to Google.com<span class="nonvisual-indicator"> (external link)</span></a>
// -----------------------------------------------------------------------------
a.external {
    @include rem(padding-right,12);
    @include rem(margin-right,4);

    &:after {
        @include rem(margin-top,8);
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background-position: 0 $icon-bg-blue;
        background-size: 10px;
        background-image: url(data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2014%20140%22%20enable-background%3D%22new%200%200%2014%2014%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2214px%22%20height%3D%22140px%22%3E%3Cg%20id%3D%22a%22%3E%20%3Cpolygon%20points%3D%2213.4%2C7.2%2013.4%2C0.6%206.8%2C0.6%209.2%2C3%204.9%2C7.3%206.7%2C9.1%2011%2C4.8%20%20%22%3E%3C%2Fpolygon%3E%20%3Cpath%20d%3D%22M12.5%2C8.3v3.9c0%2C0.1-0.1%2C0.2-0.2%2C0.2H1.8c-0.1%2C0-0.2-0.1-0.2-0.2V1.8c0-0.1%2C0.1-0.2%2C0.2-0.2h4v-1H1.8%20%20c-0.7%2C0-1.1%2C0.5-1.1%2C1.1v10.5c0%2C0.7%2C0.5%2C1.1%2C1.1%2C1.1h10.5c0.7%2C0%2C1.1-0.5%2C1.1-1.1V8.3H12.5z%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%2214%22%20fill%3D%22%23ffffff%22%3E%3C%2Fuse%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%2228%22%20fill%3D%22%23236FA6%22%3E%3C%2Fuse%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%2242%22%20fill%3D%22%23005685%22%3E%3C%2Fuse%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%2256%22%20fill%3D%22%236b6a6a%22%3E%3C%2Fuse%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%2270%22%20fill%3D%22%23454444%22%3E%3C%2Fuse%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%2284%22%20fill%3D%22%23959FA6%22%3E%3C%2Fuse%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%2298%22%20fill%3D%22%2359B359%22%3E%3C%2Fuse%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%22112%22%20fill%3D%22%23FFA217%22%3E%3C%2Fuse%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%20y%3D%22126%22%20fill%3D%22%23CA4142%22%3E%3C%2Fuse%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
    }

    &:hover:after {
        background-position: 0 $icon-bg-dark-blue;
    }
}
.i-external-link {
    width: 10px;
    height: 10px;
    vertical-align: middle;
}
.theme--error, .theme--confirmation {
    a.external {
        &:after {
            background-position: 0 $icon-bg-white;
        }
    }
}
.theme--dark, .theme--strong, .theme--slate, .theme--error, .theme--confirmation {
    a.external {
        &:after {
            background-position: 0 $icon-bg-white;
        }
    }
    .theme--white {
        a.external:after {
            background-position: 0 $icon-bg-dark-blue;
        }
    }
}
