@use '../../0-settings/settings.scss' as s;

.link-footer {
  padding: 0 s.$size--300;
}

.link-footer__subtext {
  margin: s.$size--300 0;
}

.link-footer__action {
  font-size: s.$font-size--200;
}

.update__text {
  padding: 0 s.$size--450;
}
