@use '../0-settings/settings' as s;

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: s.$family--primary;
  font-size: s.$font-size--200;
  line-height: s.$line-height--standard;
  color: s.$color--body-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

// Default heading/paragraph styles for body content.
// Update with sensible defaults for project.

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: s.$line-height--narrow;
  font-weight: s.$weight--semi;

  // Ensure links inside headings inherit heading colour
  a {
    color: inherit;
  }
}

h1 {
  margin: s.$size--500 0;
  font-size: s.$font-size--600;

  &:first-child {
    margin-top: 0;
  }
}

h2 {
  margin: s.$size--400 0;
  font-size: s.$font-size--500;

  &:first-child {
    margin-top: 0;
  }
}

h3 {
  margin: s.$size--400 0;
  font-size: s.$font-size--300;

  &:first-child {
    margin-top: 0;
  }
}

h4 {
  margin: s.$size--200 0;
  font-size: s.$font-size--200;

  &:first-child {
    margin-top: 0;
  }
}

h5,
h6 {
  margin: s.$size--300 0;
  font-size: s.$font-size--100;

  &:first-child {
    margin-top: 0;
  }
}

p {
  margin: s.$size--400 0;
}

a {
  color: s.$color--primary-active;
  transition: color s.$speed--ease-default s.$easing--default;

  &:hover {
    color: s.$color--primary;
  }
}
