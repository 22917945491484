@use '../../0-settings/settings' as s;

// NOTE: overrides for styles supplied with React Datepicker library
// See: https://github.com/Hacker0x01/react-datepicker/

@import '~react-datepicker/dist/react-datepicker.css';

// Wrapper around input
.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 0 !important;
}

// Root calendar item
.react-datepicker.c-datepicker {
  margin-top: s.$size--200;

  // Theming
  border: none;
  border-radius: s.$border-radius--default;
  box-shadow: 0px 0px 30px -10px rgba(67, 51, 122, 0.4);

  // Typogrpahy
  font-family: s.$family--primary;
  font-size: s.$font-size--200;
  color: s.$color--body-text;

  :before,
  :after {
    display: none;
  }

  // Calendar header (month/year heading, days of week headings)
  .react-datepicker__header {
    background-color: s.$color--primary;
    color: s.$color--white;
  }

  // Month heading
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: s.$font-size--300;
    color: s.$color--white;
  }

  // Week row
  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day-name {
    color: s.$color--white;
  }

  // Individual days
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    // Layout
    display: flex;
    justify-content: center;
    align-items: center;
    margin: s.$size--100;

    // Dimensions
    width: s.$size--500;
    height: s.$size--500;
    line-height: 1;

    // Theming
    border-radius: s.$border-radius--circle;
    transition: background-color s.$speed--ease-slow s.$easing--default;
  }

  // Individual day hover
  .react-datepicker__day:hover {
    border-radius: s.$border-radius--circle;
    background-color: s.$color--gray-light;
  }

  // Highlight keyboard focus on day
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: s.$color--gray-light;
    color: s.$color--body-text;
  }

  // Selected individual day
  .react-datepicker__day--selected {
    background-color: s.$color--primary;
    font-weight: s.$weight--bold;
  }

  // Selected individual day hover
  .react-datepicker__day--selected:hover {
    background-color: s.$color--primary-active;
  }

  // Left/right arrows next to month heading
  .react-datepicker__navigation {
    width: s.$size--500;
    height: s.$size--500;
    background-color: #fff;
  }

  // Left chevron
  .react-datepicker__navigation--previous {
    mask-image: url('../../../assets/icons/svgs/chevron-left.svg');
  }

  // Right chevron
  .react-datepicker__navigation--next {
    mask-image: url('../../../assets/icons/svgs/chevron-right.svg');
  }
}
