@use '0-settings/settings.scss' as s;
@use '2-resets/generic.scss';
@use '3-base/base.scss';
@use '4-layout/layout.scss';
@use '5-components/components.scss';
@use '6-trumps/trumps.scss';
@use './1-mixins/breakpoints';
@use './1-mixins/container';



.visibility-none{
    position: absolute;
    visibility: hidden;
} 