/**
* Color variables (generated using: http://chir.ag/projects/name-that-color/)
* NOTE: this file is the only place hex/rgba values should appear. All other
* Sass files should reference colours listed in this file by variable name only.
*/

// Primary colour and shades/tints
$color--jellybean: #2575ae;
$color--regal-blue: #00456b;
$color--dark-blue: #003b5c;
$color--mid-blue: #236FA6;

// Blacks/grays
$color--dove-gray: #666666;
$color--silver-chalice: #b2b2b2;
$color--athens-gray: #f9f9fa;
$color--alto: #dddddd;
$color--emperor: #555555;

// Status colours
$color--mystic: #e2e6fa;
$color--fruit-salad: #49a74e;
$color--high-contrast-green: #06893a;
$color--celery: #b4c656;
$color--tulip-tree: #de6d17;
$color--pearl-bush: #f9f5f1;
$color--well-read: #bc3832;
$color--grenadier: #d14600;
$color--wafer: #f1e1e6;

$color--lochmara: #006fb8;

$color--white: #fff;
$color--black: #000;

//One-offs
$color--cerulean: #00abdc; // file-button-border
$color--mid-grey: #999;
$color--indicator-green: #59b357;
$color--indicator-red: #db0000;
$color--indicator-yellow: #ff9600;

/**
* Common global aliases
*/

// Theming
$color--primary: $color--jellybean;
$color--primary-active: $color--regal-blue;
$color--text-on-primary: $color--white;

// Secondary color
$color--secondary: $color--fruit-salad;

// Typography
// TODO: propose we also have a disabled text colour option — need to consider for buttons and inputs.
$color--body-text: $color--emperor;
$color--subtle-text: $color--dove-gray;
$color--disabled-text: $color--dove-gray;

// States
// TODO: check if we need to have an "info" state for toasts
// TODO: check if we need a disabled state
$color--info: $color--primary;
$color--info-light: $color--mystic;
$color--success: $color--high-contrast-green;
$color--success-light: $color--celery;
$color--warning: $color--tulip-tree;
$color--warning-light: $color--pearl-bush;
$color--error: $color--well-read;
$color--error-light: $color--wafer;
$color--disabled: $color--dove-gray;

// Grays
// TODO: check if we need additional gray shades / tints
$color--gray-standard: $color--dove-gray;
$color--gray-light: $color--silver-chalice;
$color--gray-wash: $color--athens-gray;
$color--gray-lines: $color--alto;