@use '../../0-settings/settings.scss' as s;

.c-link-list {
  padding: 0;
  margin: s.$size--400 0;
  list-style: none;
}

.c-link-list__container {
  display: flex;
  flex-direction: column;
  padding-right: s.$size--450;
}

.c-link-list__item {
  padding-bottom: s.$size--400;
  margin-bottom: s.$size--400;
  display: flex;
  word-break: break-word;
  &:not(:last-child) {
    border-bottom: 1px solid s.$color--gray-lines;
  }

}

.c-link-list--small {
  display: block;
  font-size: s.$font-size--100;
  padding-left: s.$size--600;
}

.c-link-list__link {
  font-weight: s.$weight--semi;
  font-size: s.$font-size--300;
  display: block;
  transition: color s.$speed--default s.$easing--default;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: s.$size--100;
    left: s.$size--100;
    height: calc(100% - #{s.$size--200});
    border-left: s.$size--100 solid s.$color--success;
    opacity: 0;
    transform: translateX(-#{s.$size--100});
    transition: transform s.$speed--default s.$easing--default, opacity s.$speed--default s.$easing--default;
  }
}

.c-link-list--type-nav {
  margin: 0;

  .c-link-list__item {
    padding-top: s.$size--400;
    margin: 0;
    position: relative;
  }

  .c-link-list__link {
    padding-left: s.$size--450;
  }

  .c-link-list__link--small {
    font-size: s.$font-size--100;
  }
}

.c-link-list__link--active {
  text-decoration: none;
  color: s.$color--emperor;

  &:hover {
    color: s.$color--emperor;
  }

  &:before {
    transform: translateX(0);
    opacity: 1;
  }
}

.c-link-list__link.c-link-list__link--icon {
  padding-left: s.$size--300;
  word-break: break-word;
}

.c-link-list__link--small {
  display: block;
  font-size: s.$font-size--100;
  padding-left: s.$size--800;
}

.c-link-list--type-small {
  margin-left: s.$size--625;
  .c-link-list__link {
    font-size: s.$font-size--200;
  }
}

.c-link-list__icon {
  margin-left: s.$size--450;
  flex-shrink: 0;
}

.link-list{
  display: flex;
  flex-direction: column;
  .link-list__anchor{
    font-size: 16px;
    line-height: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-decoration:none;
    display:flex;
    align-items: center;
    border-bottom: s.$color--silver-chalice 1px solid;
    &:first-child {
          border-top: s.$color--silver-chalice 1px solid;
    }
  }
}
