@use '../0-settings/settings.scss' as s;
@use '../1-mixins/breakpoints';

.c-key-journey__nav-panel {
  & > .c-panel-header {
    display: none;

    @include breakpoints.tablet-portrait-up {
      display: block;
    }
  }
}

.c-panel-header--journeys-heading{
  h1{
    margin-bottom: 0;
  }
}


.c-panel-header--journeys-heading + span {
  margin-bottom: 24px;
  display:block;
  color: s.$color--error
}
.c-key-journey__list {
  display: none;

  @include breakpoints.tablet-portrait-up {
    display: block;
  }
}

.c-key-journey-detail-footer {
  padding: s.$size--450;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: s.$shadow--100;
  z-index: s.$z--sidebar;

  @include breakpoints.tablet-portrait-up {
    display: none;
  }
}

.c-key-journey-detail-footer__heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.c-key-journey-detail-footer__name {
  margin: 0;
}

.c-key-journey-detail-footer__time {
  margin: 0;
  font-size: s.$font-size--300;
  font-weight: s.$weight--semi;
  flex: 1 0 auto;
  margin-left: s.$size--300;
  text-align: right;
}

.c-key-journey-detail-footer__subtext {
  margin: s.$size--100 0 s.$size--200 0;
}

.c-key-journey-detail-footer__btn {
  padding-left: 0;
}
