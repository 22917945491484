@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-traffic-segment {
  padding-left: 24px;
  margin-left: 24px;
  border-left: 2px solid;
  position: relative;
  padding-bottom: 40px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    transform: translateX(-50%);
  }
  &:last-child::after {
    content: '';
    top: 100%;
    background: s.$color--success;
  }
}

.c-traffic-segment--red {
  border-color: s.$color--error;
  &:before {
    border-radius: 0px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 12px solid s.$color--error;
  }
}
.c-traffic-segment--orange {
  border-color: s.$color--warning;
  &:before {
    border-radius: 0px;
    background: s.$color--warning;
  }
}
.c-traffic-segment--green {
  border-color: s.$color--success;
  border-left-style: dashed;
  &:before {
    background: s.$color--success;
  }
}

.c-traffic-segment__title {
  margin-bottom: 8px;
}
