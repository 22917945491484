@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';
@use '../../5-components/navigation/header';

// Private vars
$_shadow: s.$shadow--200;

.c-navigation-panel {
  // Layout
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  overflow-y: auto;
  @include breakpoints.tablet-portrait-up {
    max-height: calc(100vh - #{header.$header-height-desktop});
  }
  // Theming
  background-color: s.$color--white;
}

.c-navigation-panel__section {
  padding: s.$panel-item-padding--mobile;

  @include breakpoints.tablet-portrait-up {
    padding: s.$panel-item-padding--desktop;
  }
}
.c-navigation-panel__section--no-padding {
  padding: 0;
}

.c-navigation-panel__section img {
  max-width: 100%;
  height: auto;
}

.c-navigation-panel__section--no-vert-padding {
  padding-top: 0;
  padding-bottom: 0;
}
.c-navigation-panel__section--no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}

.c-navigation-panel__section--shadow-bottom {
  @include breakpoints.phone-only {
    box-shadow: 0 0 10px rgba(67, 51, 122, 0.3);
    clip-path: polygon(0 0, 100% 0, 100% 200%, 0 200%);
  }

  @include breakpoints.tablet-portrait-up {
    border-bottom: 1px solid s.$color--gray-lines;
  }
}

.c-navigation-panel__section--border-bottom {
  border-bottom: 1px solid s.$color--gray-lines;
}

.c-navigation-panel__section--border-top {
  border-top: 1px solid s.$color--gray-lines;
}
