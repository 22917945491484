@use '../../0-settings/settings' as s;

// Private vars
$_circle-size: s.$size--200;
$_toggle-width: s.$size--600;

.c-toggle-button__label {
  display: flex;
  align-items: center;
}

.c-toggle-button__input {
  display: none;
}

.c-toggle-button__switch {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
  width: $_toggle-width;
  border-radius: 99px;
  background: s.$color--dove-gray;
  margin-left: s.$size--300;
}

.c-toggle-button__switch[data-is-checked='true'] {
  justify-content: flex-end;
  background: s.$color--success;
}

.c-toggle-button__status {
  width: s.$size--400;
}

.c-toggle-button__circle {
  content: '';
  height: $_circle-size;
  width: $_circle-size;
  border-radius: $_circle-size;
  background: white;
}
