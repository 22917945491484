@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';
@use '../../1-mixins/container';

// Private vars
$_logo-height: 32px;
$_banner-padding-mobile: s.$size--300;
$_banner-padding-desktop: s.$size--500;

// Public vars
$header-banner-height-mobile: $_logo-height + ($_banner-padding-mobile * 2);
$header-banner-height-desktop: $_logo-height + ($_banner-padding-desktop * 2);
$header-menu-height-desktop: 60px;
$header-height-desktop: $header-banner-height-desktop + $header-menu-height-desktop;

.c-header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 5000;

  @include breakpoints.tablet-landscape-up {
    position: relative;
    box-shadow: 0 0 10px rgba(67, 51, 122, 0.3);
  }
}

.c-header__banner {
  background-color: s.$color--primary-active;
  color: s.$color--white;
  position: relative;
  z-index: 10;
  padding: $_banner-padding-mobile;

  @include breakpoints.tablet-landscape-up {
    padding: $_banner-padding-desktop 0;
  }
}

.c-header__logo-link {
  display: block;
  height: $_logo-height;
  width: 193px;
  overflow: hidden;

  @include breakpoints.tablet-portrait-up {
    width: 332px;
  }
}

.c-header__logo-img {
  height: 100%;
  width: 100%;
}

.c-header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoints.ipad-portrait-up {
    padding-left: s.$size--600;
    padding-right: s.$size--600;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoints.small-desktop-up {
    max-width: 1200px;
    padding: 0;
  }
}

.c-header__menu-btn {
  display: flex;
  background: none;
  border: none;
  padding: 0;
  color: s.$color--white;

  @include breakpoints.tablet-landscape-up {
    display: none;
  }
}

.c-header__menu-btn-icon {
  display: none;
}

.c-header__menu-btn-icon--active {
  display: block;
}

.c-header__nav {
  flex: 1 0 100%;
}

.c-header__menu {
  // Dimensions
  width: 100%;

  // Theming
  background-color: s.$color--white;

  // Mobile styles
  @include breakpoints.tablet-landscape-down {
    // Positioning
    position: absolute;
    right: 0;
    z-index: 5;

    // Dimensions
    max-width: 280px;
    height: 100vh;
    overflow-y: auto;

    // State/animation
    transform: translateX(100%);
    transition: transform s.$easing--default s.$speed--default;
  }

  @include breakpoints.small-desktop-up {
    height: $header-menu-height-desktop;
  }
}

.c-header__menu--active {
  @include breakpoints.tablet-landscape-down {
    transform: translateX(0);
    box-shadow: 0 0 20px rgba(67, 51, 122, 0.3);
  }
}
