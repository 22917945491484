/**
* Any z-indexes required for layering key elements of the UI should be set here
* so that they can be compared and prioritised appropriately. Examples of
* elements that would require the use of this index are modals, banners,
* alerts, menus etc.
*/

// Private vars
$_z--5000: 100;
$_z--100: 100000;
$_z--75: 75;
$_z--50: 50;
$_z--25: 25;
$_z--20: 20;
$_z--0: 0;

// Public vars

// Modal / other trumps (there should be very few)
$z--modals: $_z--100;

// Site-wide header
$z--header: $_z--5000;
$z--footer: $_z--25;

// Map tool?
$z--dropdown-menu: $_z--50;
$z--pop-out-panel--mobile: $_z--100;
$z--sidebar: $_z--25;
$z--pop-out-panel: $_z--20;
