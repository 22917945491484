/*------------------------------------*\
    #HEADER STYLES
\*------------------------------------*/

.header {
    position: relative;
}

.header__image {

    min-height: 4em;
    overflow: hidden;

    @include up-to(small) {
        position: relative;
    }

    img {
        @extend %block-element;
        @extend %full-width;
        //todo wtf
        @include between(medium, medium) {
            width: 150%;
            margin-left: -25%;
            margin-right: -25%;
        }
    }
}

.header__title {
    
    @include rem(padding-bottom, $space-m);
    //@extend %pb-l--q-large;
    @include media('large') {
        @include rem(padding-bottom, $space-xxxxl);
    }
    
    .header__image + & {
        @extend %absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    
    @include up-to(small) {
        padding-bottom:0;
        .breadcrumb {
            margin-top:0;
            @include rem(margin-bottom, $space-xxs);
        }
        .header__image + & {
            //@include rem(padding-top, $space-m);
            position: relative;
            background-color: transparent;

            & .breadcrumb {
                position: relative;
                bottom: 100%;
                left: 0;
                right: 0;
            }
        }
    }
}