.home__banner {
  position: relative;
  overflow-x: hidden; // TODO: Laura, is browser support OK with this?
}

.home__image {
  display: block;
  bottom: 0;
  width: 175%;
  margin-left: -37.5%;
  @include medium() {
    width: 150%;
    margin-left: -25%;
    margin-top: -25%;
  }
  @include large() {
    width: 100%;
    margin-left: 0;
    margin-top: -12.5%;
  }
}

.home__message {
  position: absolute;
  top: 0;
  width: 100%;
  color: $white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.375);
  padding-top: 12.5%;
  @include small() {
    padding-top: 18.75%;
  }
  @include medium() {
    padding-top: 6.25%;
  }
}

.theme--banner {
  color: #236fa6;
}
