/*------------------------------------*\
    #TRAFFIC - PAGE SPECIFIC
\*------------------------------------*/

// setup

$color-map-nav: #6b6a6a;
$color-map-nav-hover: #454444;
$color-border: #eeeeee;

$offset-map-top: 1em;
$offset-map-top-mobile: 1em;
$offset-map-bottom: 1.5em;
$offset-map-side: .75em;


.traffic-info {
//    max-width: 100%;
    & .global-nav {
        position: relative;
        @include up-to(small) {
            @include rem(padding-bottom, 18);
            @include rem(padding-top, 20);
        }
    }
//    & .global-nav > .wrapper {
//        max-width: none;
//    }
    & .global-nav > .wrapper > .layout {
        @include rem(padding-left, 32);
        @include rem(padding-right, 16);
    }
    h1, h2, h3 {
        position: relative;
        @include rem(padding-right, 8);

        &.highway-count__1 {
            @include rem(padding-right, 16);
        }

        &.highway-count__2 {
            @include rem(padding-right, 48);
        }
    }
}


// accessibility

.traffic-info {
    .access:focus,
    .access-keys:target {
        position: fixed;
        z-index: 11;
        top:$offset-map-top - 2em;
        bottom: 0;
        left: 0;
        right: 0;
    }
}




%nav {
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    & > li {
        margin-top: 0;
    }
}

.section--map {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    & > * {
        width: 100%;
        height: 100%;
    }
}


%map-box-shadow {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.4);
}



a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}



/*
    map list
*/

$padding-traffic-info-side: 1.5em;
$padding-traffic-info: 1.25em;
$margin-traffic-info-side: 32;


%map-box {
    @extend %map-box-shadow;
    @include vendor(transition, left .25s ease-out);
    @include vendor(border-radius, 2px);
    background-color: #fff;
    z-index: 1;
    position: absolute;
    top: $offset-map-top;
//    min-width: 16em;
//    max-width: 20em;
}

.list-view {

    @extend %map-box;

    @include vendor(border-top-left-radius, 0);
    @include vendor(border-bottom-left-radius, 0);

    bottom: $offset-map-bottom;
    // left: $offset-map-side;

    overflow-x: hidden;
    overflow-y: auto;

    min-width: 20em;
    max-width: 24em;
    width: 40%;

    @include up-to(small) {
        width: 100%;
        @include vendor(border-radius, 0);
        @include rem(padding-bottom, $vertical-spacing-m);
        max-width: none;
        display: none;

        top: auto !important;
        margin-top: 0;
        bottom: auto;
    }
    .list-active & {
        @include up-to(small) {
            display: block;
            position: relative;

            & .view-absolute {
                position: relative;
            }
        }
    }

    .modal-active & {
        @include up-to(small) {
            position: absolute;
        }
    }
}

.modal-view {

    @extend %map-box;

    @include vendor(border-top-left-radius, 0);
    @include vendor(border-bottom-left-radius, 0);

    overflow-x: hidden;
    overflow-y: auto;

    min-width: 20em;
    max-width: 24em;
    width: 40%;
    left: -26em;
    position: relative;

    @include up-to(small) {
        width: 100%;
        @include vendor(border-radius, 0);
        max-width: none;
        position: relative;
        top: auto;
        display: none;
        @include vendor(transition, left 0s);
        left: 0;
        bottom: 0;
        margin-top: 0;
    }
    .modal-active & {
        display: block;
    }

    ul {
        margin-bottom: 0px;
    }
}

.modal-active  {
    & .list-view {
        left: -20em;
        width: 20em;
    }
    & .modal-view {
        left: 1em;
        @include up-to(small) {
            left: 0;
        }
    }
}



.traffic-info__nav {
    & .nav__link {
        color: $base-font-color;
        display: block;
        padding: $padding-traffic-info 0 $padding-traffic-info $padding-traffic-info-side;
        text-transform: uppercase;
        & > span {
            display: inline-block;
            vertical-align: middle;
            @include font-size(14);
        }
    }
}



.traffic-info__header {

    @extend %theme--dark;
    @extend %vertical-spacing-top-s;
    @extend %vertical-spacing-bottom-m;

    @include rem(padding-left, $margin-traffic-info-side);
    @include rem(padding-right, $margin-traffic-info-side);

    .breadcrumb {
//        margin-top: 0;
        @include rem(margin-top, 2);
        @include rem(margin-bottom, 8);
        @include rem(padding-right, 96);
//        padding-right: 6rem;
    }
    .breadcrumb + h1 {
        margin-top: 0;
    }
    h1 {
        margin-top: 1.3em;
        @extend %mb-0;
        @extend %font-size-xxl;
    }
    h1 + h2 {
        margin-top: .5em;
    }
    h2 {
        @extend %mb-0;
        @extend %font-size-l;
    }

    .switch-nav {
        margin-top: 1em;
    }

    position: relative;
    width: 100%;

    .level-1 & {
        @include rem(margin-bottom,16);
        &:after {
            position: absolute;
            @include rem(left,$margin-traffic-info-side);
            @include rem(height,16);
            @include rem(bottom,-16);
            right: 0;
            content:"";
            background-repeat: repeat-x !important;
        }
    }
}

.traffic-metrics {
    @extend %vertical-spacing-m;
    margin-left: 1%;
    & > * {
        @extend %inline-block;
        vertical-align: middle;
        width: 32%;
    }
}
.traffic-messages {
    @extend %vertical-spacing-top-xs;
    @extend %vertical-spacing-bottom-m;
    .list__link + & {
        padding-top: 0;
    }
}
.traffic-metrics + .traffic-messages {
    @include rem(margin-top, -16);
}



.traffic-info__body {

    h5,
    p {
        @extend %mb-0;
        margin-top: .1em;
        @extend %font-size-s;
    }

    // right align pills and icons when following a headline
    h4 {
        @extend %font-size-l;
    }
    h3,
    h4 {
        @extend %mb-0;
        @extend %clearfix;

    }
    h3 {
        @extend %font-size-xl;
        & > i {
            margin-top: 0.2em;
            margin-right: -.5em;
        }
    }

}

.location-list {

    @extend %list-unstyled;

    .level-1 & {
        @include rem(margin-left,$margin-traffic-info-side);
        @include rem(margin-right,$margin-traffic-info-side);
    }
    .level-1 & {
        border-bottom: 1px solid $color-border;
    }
    .level-1 & > .list__item > a {
        @extend %link-hover-color;
        padding-left: 0;
        padding-right: 0;
    }

    & > .list__item {
        margin-top: 0;
    }
    & > .list__item + .list__item {
        border-top: 1px solid $color-border;
    }

    & > .list__item > * {
        @include rem(padding-left,$margin-traffic-info-side);
        @include rem(padding-right,$margin-traffic-info-side);
    }
    & > .list__item.full-width > * {
        padding-left: 0;
        padding-right: 0;
    }

    & > .list__item > a {
        padding-top: $padding-traffic-info;
        padding-bottom: $padding-traffic-info;
    }
    // reduce padding for pills in list
    & .list__link .pill {
        padding: .071428571em .285714286em .142857143em;
    }
    & .list__link {
        display: block;
    }
    h4 {
        margin-bottom: 0;
    }
}


.location-list__split {
    float: right;
}


@import "components/traffic/traffic-nav";
@import "components/traffic/route";
@import "components/traffic/webcam";
@import "components/traffic/switch-nav";



.horizontal-scroll {
    @extend %vertical-spacing-bottom-m;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
}






/*
    custom map icons
*/

.i-map-caret-down,
.i-map-caret-up,
.i-map-plus,
.i-map-minus,
.i-map-compass,
.i-map-cog {
    width: .875em;
    height: .875em;
}

.i-map-tools-cameras,
.i-map-tools-roadworks,
.i-map-tools-warnings,
.i-map-tools-area-warnings,
.i-map-tools-projects,
.i-map-tools-timsigns,
.i-map-tools-vmssigns,
.i-map-tools-closures {
    @include rem(width,26);
    @include rem(height,34);
    margin-top: -0.2em;
}
.i-map-tools-traffic-flow {
    @include rem(width,20);
    @include rem(height,18);
    @include rem(margin-left,3);
    @include rem(margin-right,3);
}


//custom traffic icons

.i-sh {
    width: 1.625em;
    height: 1.625em;
}
.i-route{
    width: .875em;
    height: 1.25em;
}
.i-twitter,
.i-facebook,
.i-google {
    width: 1.375em;
    height: 1.375em;
}

.i-distance,
.i-speed,
.i-duration {
    width: 1.5em;
    height: 1.5em;
}
.i-limit {
    border: .3em solid #CA4142;
    border-radius: 2em;
    display: block;
    font-size: 1.2em;
    font-style: normal;
    font-weight: bold;
    height: 2.3em;
    line-height: 1.7em;
    text-align: center;
    width: 2.3em;
}


// state highway sign
.highway {
    position: relative;
    display: inline-block;

    h1 > &,
    h2 > &,
    h3 > &{
        @extend %font-size-l;
        @extend %font-weight-bold;
        position: absolute;
        @include rem(top,4);
        @include rem(right,-12);
    }
}


    .highway__number {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        text-align: center;
        color: #fff;
    }

    .highway--white .highway__number {
        color: #333;
    }

.view-relative {
    position: relative;
}

.view-absolute {
    position: absolute;
    background: #fff;
    width: 100%;
//    display: none;
}






/**
 *  Traffic animation
**/

$durationTransition: 200ms;
$easingCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

@include keyframe(slideInFromRight) {
    0%{
        @include vendor(transform, translateX(100%));
    }
    100%{
        @include vendor(transform, translateX(0%));
    }
}
@include keyframe(slideOutToRight) {
    0%{
        @include vendor(transform, translateX(0%));
    }
    100%{
        @include vendor(transform, translateX(100%));
    }
}
@include keyframe(fadeIn) {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.anim-reveal {
    @include vendor(animation, slideInFromRight $durationTransition both $easingCubic);
}
.anim-slidein {
    @include vendor(animation, slideInFromRight $durationTransition both $easingCubic);
}
.anim-slideout {
    @include vendor(animation, slideOutToRight $durationTransition both $easingCubic);
}


.anim-fadein {
    @include vendor(animation, fadeIn $durationTransition both $easingCubic);
}

