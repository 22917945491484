/*------------------------------------*\
  Form component overrides:
\*------------------------------------*/

.field {
  -webkit-appearance: none;
  border: none;
  background: none;
  padding: 0px;

  .checkbox {
    margin: 5px;
  }
}

.label {
  font-weight: 600;
}

#route-edit-form .field__label {
  font-weight: 400;
}

.btn-toolbar .action {
  min-width: 35%;
  background: #236FA6;
  color: #fff;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 12px;
  margin-bottom: 1rem;
  text-align: center;
}

.btn-toolbar .action-link {
  display: inline-block;
  margin-right: 18px;
}

.input-like {
  border-radius: 0.25rem;
  -webkit-appearance: none;
  border: 1px solid #00456B;
  background-color: #F2F2EB;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.large-right-col {
  margin-top: 30px;
}

@media only screen and (min-width: 60em) {
  .large-right-col {
    margin-top: 0;
    padding-left: 3rem;
  }

  .left-border-col {
    border-right: 1px solid;
    padding-right: 3rem;
  }

  .right-border-col {
    border-left: 1px solid;
  }
}

.field.checkbox label, .field-row {
  margin-bottom: 16px;
  margin-bottom: 1rem;
  float: none;
}

.field label, .field-row label {
  display: inline-block;
}

.col-like {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}

.field .message.required, .field .message.validation, .message.bad {
  margin-bottom: 1rem;
  display: inline-block;
  color: $ms-red;
}

.holder-required {
  input, select, textarea {
    border-color: $ms-red;
  }
}
