@use '../0-settings/settings.scss' as s;

.l-region-overview {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.l-region-overview__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 calc(100% / 3);
  margin-bottom: s.$size--500;
  color: s.$color--lochmara;

  &:hover {
    color: s.$color--lochmara;
  }
}

.l-region-overview .l-region-overview__icon {
  width: 64px;
  height: 64px;
  margin-bottom: 8px;
}

.l-region-overview__label {
  text-align: center;
}

.l-region-overview__save-notifications {
  display: flex;
  align-items: center;
  font-size: s.$font-size--300;
}

.l-region-overview__save-notifications .c-icon {
  margin-right: s.$size--200;
}
