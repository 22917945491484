@import '../lib-sass/config';

.traffic-cameras {
    .traffic-cameras__thumbnail, .traffic-cameras__show-more {
        position: relative;
        display: inline-block;
        width: 49%;
        @include medium() {
            width: 32%;
        }
        @include large() {
            width: 19.5%;
        }

        .traffic-cameras__thumbnail--image {
            padding-bottom: 100%;
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        .traffic-cameras__thumbnail--name {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            margin: 0;
            background-color: rgba(1,1,1,0.6);
            color: $white;
            width: 100%;
            padding: .2rem .5rem;
        }
    }
    .traffic-cameras__show-more {
        .traffic-cameras__show-more--box {
            padding-bottom: 100%;
            background: $base-link-color;
            text-align: center;
            color: $white;
            &:hover {
                background: $base-link-hover-color;
            }
            .traffic-cameras__show-more--content {
                position: absolute;
                top: 50%;
                margin-top: -0.8rem;
                width: 100%;
                text-align: center;
            }
        }
    }

    .traffic-cameras__full {
        max-width: 100%;
    }
}
