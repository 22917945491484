@use '../0-settings/settings' as s;
@use '../1-mixins/breakpoints';

.l-traffic-bulletin-page__main-content {
  flex: 1;
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
}

.l-traffic-bulletin-page__main-content .traffic-cameras__thumbnail {
  width: 49% !important;
}

.traffic-bulletin-page__content {
  margin-bottom: s.$size--600;
}

.traffic-bulletin-page__divider {
  border-top: 1px solid s.$color--gray-lines;
}

.traffic-bulletin-page__title {
  font-weight: s.$weight--semi;
  font-size: s.$font-size--600;
}

.traffic-bulletin-list-item__heading {
  font-weight: s.$weight--bold;
  font-size: s.$font-size--600;
}

.traffic-update-item__sub-heading {
  font-weight: s.$weight--semi;
  font-size: s.$font-size--500;
}

.traffic-bulletin-page__info {
  font-weight: s.$weight--regular;
  margin-bottom: s.$size--625;
}

.traffic-update__link {
  margin-top: s.$size--400;
  color: s.$color--mid-blue;
}

.traffic-bulletin-item__link {
  margin-top: s.$size--400;
  text-decoration: none;
}

.traffic-bulletin-item__link:hover {
  color: s.$color--mid-blue;
}

.traffic-bulletin-list {
  margin-bottom: 75px;
}

.traffic-bulletin-item {
  border-bottom: 1px solid s.$color--gray-lines;
  padding: s.$size--450 0;
}

.traffic-update-list {
  border-top: 1px solid s.$color--gray-lines;
  padding: s.$size--450 0;
  margin: 0 0 40px;
  overflow: hidden;

  dl {
    margin-bottom: 16px;
  }

  dd,
  dt {
    font-size: s.$font-size--200;
    line-height: s.$line-height--large;
    float: left;
    padding: 0;
    margin: 0 0 10px;
    border: none;
  }

  dt {
    width: 40%;
    clear: left;
    font-weight: s.$weight--semi;
  }

  dd {
    width: 60%;
    text-align: left;
  }

  .def-list__big-item {
    font-size: 20px;
  }
}

.traffic-bulletin-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 8px;
  bottom: s.$size--900;
  width: 100%;
  max-width: s.$size--1500;
  color: s.$color--mid-blue;
  align-items: center;
  height: s.$size--450;
  margin-bottom: 100px;

  a {
    padding: 0 s.$size--200 0 s.$size--200;
    text-decoration: none;
  }
}

.traffic-bulletin-pagination__current-page {
  padding: 0 8px 0 8px;
  font-weight: s.$weight--bold;
  font-size: s.$font-size--300;
}

.traffic-bulletin-page__select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: s.$size--500;

  label {
    font-weight: s.$weight--semi;
    font-size: s.$font-size--300;
  }
}

.traffic-bulletin-page__select {
  height: s.$size--700;
  max-width: 342px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: s.$size--450;

  select {
    border-radius: 4px;
    font-size: s.$font-size--300;
    color: s.$color--mid-blue;
    padding: s.$size--300;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.traffic-bulletin-page__select-chevron {
  font-weight: s.$weight--semi;
  position: absolute;
  content: '';
  width: s.$size--450;
  height: s.$size--450;
  margin-right: s.$size--200;
  align-self: flex-end;
  background-repeat: no-repeat;
  background-color: s.$color--mid-blue;
  background-image: url('../../assets/icons/svgs/chevron-down.svg');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-image: url('../../assets/icons/svgs/chevron-down.svg');
  pointer-events: none;
}