@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';
@use '../../1-mixins/container';

$_te-reo-label-font-size: s.$font-size--100; //12px (1.2rem)
$_english-label-font-size: s.$font-size--200; //14px (1.4rem)

.c-main-menu {
  padding: 0;
  margin: 0;
  list-style: none;

  @include breakpoints.tablet-landscape-up {
    display: flex;
    justify-content: flex-start;
  }

  @include breakpoints.small-desktop-up {
    align-items: flex-end;
  }
}

.c-main-menu__item {
  list-style: none;

  @include breakpoints.tablet-landscape-down {
    border-bottom: 1px solid s.$color--silver-chalice;
  }
}

.c-main-menu__item--right-align {
  @include breakpoints.tablet-landscape-up {
    margin-left: auto;
  }
}

.c-main-menu__link {
  // Layout
  display: flex;
  justify-content: space-between;
  align-items: center;

  // Dimensions
  padding: s.$size--400 s.$size--300 s.$size--400 s.$size--400;

  // Typography
  color: s.$color--primary-active;
  text-decoration: none;

  @include breakpoints.tablet-landscape-up {
    padding: s.$size--300 0;
    margin-right: s.$size--500;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 4px;
      height: 2px;
      width: 100%;
      background: s.$color--success;
      opacity: 0;
      transform: translateY(0);
      transition: transform s.$easing--default s.$speed--default, opacity s.$easing--default s.$speed--default;
    }

    &.c-main-menu__link--active:after,
    &:hover:after {
      opacity: 1;
      transform: translateY(-3px);
    }
  }
}

.c-main-menu__link--with-icon .c-main-menu__link-icon {
  @include breakpoints.tablet-landscape-up {
    order: -1;
    margin-right: s.$size--100;
  }
}

.c-main-menu__link-text {
  display: flex;
  flex-direction: column;
}

.c-main-menu__te-reo-label {
  font-size: $_te-reo-label-font-size;
  font-weight: s.$weight--semi;
  line-height: 1.5;
}

.c-main-menu__english-label {
  font-size: $_english-label-font-size;
}

.c-main-menu__link-icon--mobile-only {
  @include breakpoints.tablet-landscape-up {
    display: none;
  }
}
