@use '../../0-settings/settings.scss' as s;
/**
 * Condition Item
 * */

.c-condition-item {
  display: flex;
  flex-direction: column;
}

.c-condition-item__accordion {
  display: flex;
  align-items: center;
}

.c-condition-item__accordion--clickable {
  cursor: pointer;
  background: transparent;
  border:0;
  padding: 0;
  
}

.c-condition-item__hr {
  flex: 1;
  height: 1px;
  border: none;
  background-color: s.$color--gray-lines;
}
.c-condition-item__icon {
  flex: 0 0 auto;
  margin: 4px;
}

.c-condition-item__label {
  flex: 0 1 auto;
  padding-right: 8px;
  font-size: 12px;
  margin-left: 4px;
}

.c-condition-item__chevron {
  flex: 0 0 auto;
  margin-left: 8px;
  color: s.$color--primary;
}

/**
 * Condition Card
 * */

.c-condition-card {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-bottom: 1px solid s.$color--gray-lines;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.c-condition-card__label {
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 600;
}

.c-condition-card__list {
  padding-left: 0;
  margin-left: 4px;
}
