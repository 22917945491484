@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';
@use '../../1-mixins/container';

.c-footer {
  position: relative;
  z-index: s.$z--footer;
  background-color: s.$color--dark-blue;
  height: 290px;

  @include breakpoints.ipad-portrait-up {
    height: 130px;
    margin-bottom: -50px;
  }
}

.c-footer__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: s.$size--500;
  padding-bottom: s.$size--500;

  @include breakpoints.ipad-portrait-up {
    padding-left: s.$size--600;
    padding-right: s.$size--600;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoints.narrow-desktop-range {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include breakpoints.small-desktop-up {
    max-width: 1200px;
    padding: 0;
  }
}

.c-footer__content-row {
  display: flex;
  flex-direction: column;
  padding-left: s.$size--450;
  padding-right: s.$size--450;
  justify-content: space-between;

  @include breakpoints.ipad-portrait-up {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 65px;
    padding: 0;
  }

  &:not(:last-child) {
    @include breakpoints.narrow-desktop-range {
      margin-bottom: 0;
    }
  }
}

.c-footer__content-row:first-of-type {
  @include breakpoints.ipad-portrait-up {
    align-items: flex-end;
  }
}

.c-footer-menu {
  font-size: s.$font-size--200;
  line-height: s.$font-size--600;
}

.c-footer-menu__item {
  list-style: none;
  color: s.$color--white;
}

.c-footer-menu__items {
  display: flex;
  list-style: none;
  color: s.$color--white;
  padding: 0;

  @include breakpoints.ipad-portrait-up {
    flex-wrap: wrap;
    margin: 0;
  }

  a:hover {
    color: s.$color--silver-chalice;
  }
}

.c-footer-menu__link {
  display: flex;
  align-items: center;
  text-underline-offset: s.$text-underline-offset;
  text-decoration-thickness: s.$text-decoration-thickness;
  color: s.$color--white;
}

.c-footer-menu__icon {
  margin-right: s.$size--200;
}

.c-footer-menu--quick-links .c-footer-menu__icon {
  width: 1em;
  height: 1em;
}

.c-footer-menu--quick-links .c-footer-menu__items {
  @include breakpoints.phone-only {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }
}

.c-footer-menu--quick-links .c-footer-menu__item {
  margin-bottom: s.$size--400;

  @include breakpoints.tablet-portrait-up {
    margin-right: s.$size--500;
  }

  @include breakpoints.ipad-portrait-up {
    flex: 1 1 auto;
  }
}

.c-footer-menu--social-links .c-footer-menu__item:not(:first-child) {
  margin: 0 0 0 s.$size--500;
  @include breakpoints.ipad-portrait-up {
    margin: 0 0 0 s.$size--400;
  }
}

.c-footer-menu--social-links .c-footer-menu__items {
  @include breakpoints.ipad-portrait-up {
    align-items: center;
  }
}

.c-footer__copyright-info {
  margin-top: s.$size--450;
  color: s.$color--white;
  font-size: s.$font-size--100;
  line-height: s.$font-size--400;

  @include breakpoints.ipad-portrait-up {
    margin: 0;
    flex-wrap: unset;
  }
}

.c-footer__icon-container {
  display: flex;
  flex-direction: column;
  align-items: start;

  @include breakpoints.ipad-portrait-up {
    flex-direction: row;
    align-items: center;
    margin-bottom: s.$size--200;
  }
}

.c-footer__nz-govt-logo {
  display: block;
  height: s.$size--650;
  margin-top: 14px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
  @include breakpoints.ipad-portrait-up {
    margin-top: 0;
    margin-left: s.$size--600;
  }
}

.c-footer__road-to-zero-logo {
  display: block;
  height: s.$size--650;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.journey-planner .l-page__footer {
  @include breakpoints.ipad-portrait-up {
    margin: 0;
    display: block;
  }
}

.c-footer-menu__icon {
  height: s.$size--450;
  width: s.$size--450;

  &.c-footer-menu__icon--about {
    width: 1.5em;
  }
  &.c-footer-menu__icon--contact {
    width: 1.4375em;
    height: 1.125em;
  }
  &.c-footer-menu__icon--search,
  &.c-footer-menu__icon--disclaimer {
    width: 1.125em;
    height: 1.125em;
  }
}
