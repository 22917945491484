@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';
@use 'sass:math';

// Public vars
$btn-height--default: s.$size--650;
$btn-height--mobile: s.$size--650;
$btn-height--large: 50px;

// Private vars
$_icon-size: s.$size--400;
$_icon-size--half: math.div(s.$size--400, 2);
$_large-icon-size: s.$size--500;
$_large-icon-size--half: math.div(s.$size--400, 2);
$_medium-icon-size: s.$size--450;
$_icon-gap: s.$size--100;

// Default button styles

.c-btn {
  // Layout
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  // Dimensions
  height: $btn-height--mobile;
  padding: 0 s.$size--400;

  // Theming
  border: none;
  border-radius: s.$border-radius--default;
  background-color: s.$color--primary;
  box-shadow: 3px 3px 10px rgba(67, 51, 122, 0.3);

  // Typography
  color: s.$color--white;
  font-weight: s.$weight--semi;
  font-size: s.$font-size--300;
  text-align: center;
  line-height: 1;
  text-decoration: none;

  // Animation
  transition: background-color s.$speed--ease-slow s.$easing--default, color s.$speed--ease-slow s.$easing--default;

  // Misc
  cursor: pointer;

  &:hover {
    background-color: s.$color--primary-active;
    color: s.$color--white;
  }

  @include breakpoints.small-desktop-up() {
    height: $btn-height--default;
    padding: 0 s.$size--300;
    font-size: s.$font-size--300;
  }
}

.c-btn__icon {
  width: $_icon-size;
  height: $_icon-size;
  margin-left: $_icon-gap;
}

.c-btn__label {
  font-weight: s.$weight--semi;
  opacity: 1;
  transition: opacity s.$speed--default s.$easing--default;
}

// Modifiers

.c-btn--secondary {
  // Box shadow emulates border in way that doesn't add 2px height
  box-shadow: 0 0 0 1px s.$color--primary;
  background: transparent;
  color: s.$color--primary;
  transition: box-shadow s.$speed--default s.$easing--default, color s.$speed--default s.$easing--default;

  &:hover {
    background: transparent;
    box-shadow: 0 0 0 1px s.$color--primary-active;
    color: s.$color--primary-active;
  }
}

.c-btn--tertiary {
  background-color: s.$color--well-read;
  &:hover {
    background-color: darken(s.$color--well-read, 0.5);
    box-shadow: 0 0 0 1px s.$color--well-read;
  }
}

.c-btn--large {
  height: $btn-height--large;
  padding: 0 s.$size--500;
  font-size: s.$font-size--300;

  .c-btn__icon {
    width: $_large-icon-size;
    height: $_large-icon-size;
  }

  .c-btn__icon--spinner {
    margin: ($_large-icon-size--half * -1) 0 0 ($_large-icon-size--half * -1);
  }

  @include breakpoints.small-desktop-up() {
    font-size: s.$font-size--400;
  }
}

.c-btn--small {
  font-size: s.$font-size--200;
  height: s.$size--500;
  .c-btn__label {
    font-weight: s.$weight--regular;
  }
}

.c-btn--medium {
  font-size: s.$font-size--200;
  height: s.$size--600;
  padding: 0 s.$size--200;
  .c-btn__label {
    font-weight: s.$weight--semi;
  }
  .c-btn__icon {
    width: $_medium-icon-size;
    height: $_medium-icon-size;
  }
}

.c-btn--text-action {
  padding-left: s.$size--300;
  padding-right: s.$size--300;
  text-decoration: underline;
  background: transparent;
  color: s.$color--primary-active;
  box-shadow: none;

  &:hover {
    background: transparent;
    color: s.$color--primary;
  }

  &.c-btn--large {
    font-size: s.$font-size--300;
  }

  .c-btn__icon--spinner {
    position: static;
    margin: 0;
  }
}

.c-btn--stretch {
  width: 100%;
}

.c-btn--disabled {
  &,
  &:hover {
    border: none;
    background-color: s.$color--gray-light;
    color: s.$color--gray-standard;
    box-shadow: none;
    cursor: default;
  }

  &.c-btn--secondary {
    background: none;
    box-shadow: 0 0 0 1px s.$color--gray-light;
  }
}

.c-btn__icon--spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: ($_icon-size--half * -1) 0 0 ($_icon-size--half * -1);
}

.c-btn__label--loading {
  opacity: 0;
}

.c-btn--icon-left .c-btn__icon {
  order: -1;
  margin-left: 0;
  margin-right: $_icon-gap;
}

.c-btn--icon-only {
  flex: 0 0 auto; // Ensure button ratio stays square
  padding: 0;
  width: $btn-height--mobile;
  height: $btn-height--mobile;

  .c-btn__icon {
    width: 18px;
    height: 18px;
    margin: 0;
  }

  @include breakpoints.small-desktop-up() {
    width: $btn-height--default;
    height: $btn-height--default;
  }
}

.c-btn--icon {
  background: none;
  color: s.$color--black;
  box-shadow: none;
  &:hover {
    background: none;
    color: s.$color--black;
  }
}

.c-btn--icon .c-btn__icon {
  width: 28px;
  height: 28px;
}

.c-btn--no-padding {
  padding: 0;
}


.c-btn--font-light{
  font-weight: 400;
  min-width: 134px;
  margin-right: 12px;
}