@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-table {
  display: table;
  border: none;
}

.c-table__header {
  background: none;
  border-bottom: 1px solid s.$color--gray-light;
}

.c-table__column-heading {
  font-weight: s.$weight--bold;
  font-size: s.$font-size--100;
  border: none;
  padding: 0;
  padding-bottom: s.$size--200;
}

.c-table__row {
  border-bottom: 1px solid s.$color--gray-light;
}

.c-table__cell {
  border: none;
  padding: 16px 0;
  position: relative;
}

.c-table__flow-state {
  display: block;
  height: s.$size--200;
  width: s.$size--200;
  border-radius: s.$size--200;
  position: absolute;
  left: -1 * s.$size--200;
  top: s.$size--500;
  transform: translateX(-100%);
}

.c-table__flow-state--red {
  background: none;
  border-radius: 0px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 9px solid s.$color--error;
}
.c-table__flow-state--orange {
  background: s.$color--warning;
  border-radius: 0px;
}
.c-table__flow-state--green {
  background: s.$color--success;
}
