@use '../../0-settings/settings' as s;

.c-checkable-fields {
  display: flex;
  flex-direction: column;
  margin-top: s.$size--300;
  margin-bottom: s.$size--300;
}

.c-checkable-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: s.$size--300;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-checkable-field__input {
  margin-right: s.$size--200;
}

.c-checkable-field__label {
  font-size: s.$font-size--200;
  line-height: 1;
}

// Horizontal layout of fields
.c-checkable-fields--horizontal {
  flex-direction: row;
  margin-bottom: 0;

  .c-checkable-field {
    margin-right: s.$size--500;
    margin-bottom: s.$size--100;
  }

  .c-checkable-field__input {
    margin-right: s.$size--100;
  }
}
