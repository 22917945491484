@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

// Private vars
$_active-highlight-height: 2px;

.c-tabs {
  width: 100%;
  max-width: 100vw;
  padding: 0 s.$panel-item-padding--mobile;
  overflow-x: auto;
  overflow-y: hidden;

  @include breakpoints.tablet-portrait-up {
    padding: 0 s.$panel-item-padding--desktop;
  }
}

.c-tabs--hide-desktop {
  @include breakpoints.tablet-portrait-up {
    display: none;
  }
}

.c-tabs__items {
  // Layout
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  margin: 0;

  // Dimensions
  padding: 0;

  // Theming
  list-style: none;
}

// Buffer for overflow content (so scroll doesn't stop against edge of last item)
.c-tabs__items:after {
  content: '';
  display: block;
  padding: 0 0 0 s.$size--600;
  height: s.$size--600;
}

.c-tabs__item:not(:last-child) {
  margin-right: s.$size--400;
}

.c-tabs__item {
  position: relative;
}

.c-tabs__tab-btn {
  padding: s.$size--400 0;
  background: none;
  border: none;
  font-size: s.$font-size--100;
  font-weight: s.$weight--semi;
  text-decoration: underline;
  color: s.$color--primary-active;
  cursor: pointer;
}

.c-tabs__tab-btn:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 0;
  bottom: 11px;
  left: 0;
  width: 100%;
  border-bottom: $_active-highlight-height solid s.$color--success;
  opacity: 0;
  transform: translateY(s.$size--100);
  transition: color s.$speed--default s.$easing--default, transform s.$speed--default s.$easing--default;
}

.c-tabs__item--active,
.c-tabs__item:hover {
  .c-tabs__tab-btn {
    color: s.$color--emperor;
    text-decoration: none;
  }

  .c-tabs__tab-btn:after {
    opacity: 1;
    transform: translateY(0);
  }
}
