@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-region-banner__container {
  width: 100vw;
  background: #bc3832;
}

.c-region-banner__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 s.$size--400 0 s.$size--400;
  background: #bc3832;
  z-index: 100;

  @include breakpoints.tablet-portrait-up {
    flex-direction: column;
    padding: 0 s.$size--400 0 s.$size--400;
  }

  @include breakpoints.tablet-landscape-up {
    flex-direction: row;
    padding: 0 s.$size--500 0 s.$size--500;
  }
}

.c-region-banner__manual-event {
  max-width: 900px;
}

.c-region-banner_existing-event {
  max-width: 900px;
}

.c-region-banner__content {
  color: s.$color--white;
  font-size: s.$font-size--300;
}

.c-region-banner__impact {
  font-weight: s.$weight--bold;
}

.c-region-banner__link {
  color: s.$color--white;
}
.c-region-banner__link:hover {
  color: s.$color--white;
}

.c-region-banner__btn-wrapper {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  justify-content: center;
  height: 40px;

  @include breakpoints.tablet-landscape-up {
    justify-content: flex-end;
  }

  @include breakpoints.tablet-landscape-up {
    align-self: auto;
    height: auto;
  }
}
.c-region-banner__btn {
  color: s.$color--white;
  text-decoration: underline;
  text-align: center;
  font-size: s.$font-size--300;
  font-weight: s.$weight--bold;
  cursor: pointer;
  align-self: flex-end;
  padding: 0 s.$size--300;

  @include breakpoints.tablet-portrait-up {
    margin-left: 40px;
  }

  @include breakpoints.tablet-landscape-up {
    align-self: center;
  }
}
