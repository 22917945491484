@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-route-details {
  display: none;

  @include breakpoints.tablet-portrait-up {
    display: block;
  }
}

.c-route-details--tab-active {
  display: block;
}

.c-route-details__headings {
  padding: s.$size--500 s.$panel-item-padding--desktop 0 s.$panel-item-padding--desktop;
}

.c-route-details__list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid s.$color--gray-lines;
}

.c-route-details__list-heading {
  margin: s.$size--600 0 s.$size--300 0;
  padding: 0 s.$panel-item-padding--desktop;
}

.c-route-detail {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding: s.$panel-item-padding--mobile;
  border-bottom: 1px solid s.$color--gray-lines;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    height: calc(100% - 8px);
    border-left: 4px solid s.$color--success;
    opacity: 0;
    transform: translateX(-4px);
    transition: transform s.$speed--default s.$easing--default, opacity s.$speed--default s.$easing--default;
  }

  @include breakpoints.tablet-portrait-up {
    padding: s.$size--400 s.$panel-item-padding--desktop;
  }
}

.c-route-detail--selected {
  &:before {
    transform: translateX(0);
    opacity: 1;
  }

  .c-route-detail__name {
    color: s.$color--emperor;
    text-decoration: none;
    cursor: default;
  }
}

.c-route-detail__icon {
  flex: 0 0 s.$size--600;
  width: s.$size--600;
  height: s.$size--600;
  margin-right: s.$size--300;
}

.c-route-detail__icon--roadworks {
  color: s.$color--grenadier;
}

.c-route-detail__name {
  display: block;
  margin-bottom: s.$size--200;
  font-size: s.$font-size--200;
  font-weight: s.$weight--semi;
}

.c-route-detail__updated {
  font-size: s.$font-size--100;
}
