@use '../../0-settings/settings' as s;

.c-icon-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .c-icon {
    margin-right: s.$size--100;
  }
}
