@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';
@use '../common/_button';
@use '../common/icon';

// Public vars
$field-padding--vertical: s.$size--200;
$field-padding--horizontal: s.$size--300;
$field-height--default: s.$size--625;
$field-height--mobile: s.$size--625;

.c-text-input {
  width: 100%;
  height: $field-height--mobile;
  padding: $field-padding--vertical $field-padding--horizontal;
  border: 1px solid s.$color--gray-standard;
  border-radius: s.$border-radius--medium;
  font-size: s.$font-size--300;
  color: s.$color--body-text;
  transition: border-color s.$speed--default s.$easing--default;

  @include breakpoints.small-desktop-up() {
    height: $field-height--default;
    font-size: s.$font-size--200;
  }

  &:hover {
    border-color: s.$color--primary;
  }

  &:focus {
    border-width: 2px;
    border-color: s.$color--primary;
    // Adjust padding to allow for extra border width (avoids text shifting):
    padding: ($field-padding--vertical - 1px) ($field-padding--horizontal - 1px);
  }
}


.c-text-input--tiny {
  max-width: s.$input-width--tiny;
}

.c-text-input--small {
  max-width: s.$input-width--small;
}

.c-text-input--medium {
  max-width: s.$input-width--medium;
}

.c-text-input--large {
  max-width: s.$input-width--large;
}

.c-text-input--default {
  max-width: s.$input-width--default;
}

.c-text-input--invalid {
  border-color: s.$color--error;
}

.c-text-input--disabled {
  &,
  &:focus,
  &:hover {
    border-color: s.$color--gray-light;
    background-color: s.$color--gray-wash;
    color: s.$color--gray-standard;
  }
}

// Input with icon positioned inside bounds (see ./_input-container.scss for related styles)
$icon-padding-allowance: ($field-padding--horizontal * 2) + icon.$icon-size--default;

.c-text-input--has-icon {
  padding-left: $icon-padding-allowance;

  &:focus {
    // Adjust padding to allow for extra border width (avoids text shifting):
    padding-left: $icon-padding-allowance - 1px;
  }
}

// Textarea specific styles
.c-text-input--textarea {
  height: s.$size--900;
}

// Select specific styles
.c-text-input--select {
  padding: 0 $field-padding--horizontal;
  appearance: none;
  // Custom down chevron image:
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.00012207L5 5.00012L10 0.00012207H0Z' fill='%23000'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - #{$field-padding--horizontal}) center;
  background-repeat: no-repeat;
  border-color: s.$color--primary;
  font-size: s.$font-size--300;

  &:focus {
    padding: 0 ($field-padding--horizontal - 1px);
  }
}

// Typeahead (React Select) component styles - styled to match vanilla select.
// Nested to increase specificity/override Emotion styles.
// See https://react-select.com/styles#using-classnames
.c-typeahead {
  width: 100%;
  max-width: s.$size--1400;

  .c-typeahead__control {
    height: button.$btn-height--mobile;
    padding: 0 $field-padding--horizontal;
    border: 1px solid s.$color--gray-standard;
    border-radius: s.$border-radius--medium;
    font-size: s.$font-size--300;
    color: s.$color--body-text;
    transition: border-color s.$speed--default s.$easing--default;
    min-height: 0;

    @include breakpoints.small-desktop-up() {
      height: button.$btn-height--default;
      font-size: s.$font-size--200;
    }

    &:hover {
      border-color: s.$color--primary;
    }

    &:focus {
      border-width: 2px;
      border-color: s.$color--primary;
      // Adjust padding to allow for extra border width (avoids text shifting):
      padding: ($field-padding--vertical - 1px) ($field-padding--horizontal - 1px);
    }
  }

  .c-typeahead__indicator-separator {
    display: none;
  }

  .c-typeahead__clear-indicator + .c-typeahead__indicator-separator {
    display: flex;
    margin-right: s.$size--300;
  }

  // TODO: replace hideous cross icon used in react-select
  .c-typeahead__clear-indicator {
    padding: s.$size--100 s.$size--200;
    cursor: pointer;
    color: s.$color--gray-standard;

    &:hover {
      color: s.$color--error;
    }
  }

  .c-typeahead__dropdown-indicator {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.00012207L5 5.00012L10 0.00012207H0Z' fill='%23000'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    width: 10px;
    height: 100%;
    padding: 0;

    // Remove custom icon included with React Select library
    svg {
      display: none;
    }
  }

  .c-typeahead__value-container {
    padding: 0;
  }

  .c-typeahead__placeholder {
    margin-left: 0;
    margin-right: 0;
    color: s.$color--body-text;
  }

  .c-typeahead__menu {
    z-index: s.$z--dropdown-menu;
  }
}


.c-form-field select{
  width: 100%;
  max-width: 128px;
  height: $field-height--mobile;
  padding: s.$size--200 s.$size--300;
  border: 1px solid s.$color--gray-standard;
  border-radius: s.$border-radius--medium;
  font-size: s.$font-size--300;
  color: s.$color--body-text;
  transition: border-color s.$speed--default s.$easing--default;

  @include breakpoints.small-desktop-up() {
    height: $field-height--default;
    font-size: s.$font-size--200;
  }

  &:hover {
    border-color: s.$color--primary;
  }

  &:focus {
    border-width: 2px;
    border-color: s.$color--primary;
    // Adjust padding to allow for extra border width (avoids text shifting):
    padding: (s.$size--200 - 1px) (s.$size--300 - 1px);
  }
}

.c-form-field{
  display:flex;
  flex-direction: column;
}

#route-alert-start-end-dates{
  @include breakpoints.tablet-landscape-down() {
    .c-form-field--group{
      flex-direction: column;
      .c-form-field{
        max-width: unset;
      }
    }
  }
}