/*
* Site Search
*/

.site-search-container {
    @extend %relative;
}

.site-search__button {
    position: absolute;
    top: 1.5em;
    left: 1.5em;
}

.site-search__input {
    padding-left: 2.5em;
}
