/**
*  ================
*   BREAKPOINTS
*  ================
*
*  Declarative mixins to keep breakpoints consistent and easy to use.
*  See: https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
*
*  Additional breakpoints can be added if needed but always best to make sure
*  components work well at all widths (rather than targeting specific devices) :)
*
*  ### USAGE: ###
*  .component {
*    display: inline-block;
*
*    @include phone-only {
*      display: none;
*    }
*  }
*
*/

@mixin phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin tablet-landscape-down {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin small-desktop-up {
  @media (min-width: 1260px) {
    @content;
  }
}

@mixin narrow-desktop-range {
  @media (min-width: 900px) and (max-width: 1260px) {
    @content;
  }
}

@mixin medium-desktop-up {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin big-desktop-up {
  @media (min-width: 1920px) {
    @content;
  }
}

// NEW

@mixin ipad-portrait-down {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin ipad-portrait-up {
  @media (min-width: 768px) {
    @content;
  }
}
