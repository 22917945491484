@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-route-results {
  display: none;

  @include breakpoints.tablet-portrait-up {
    display: block;
  }
}

.c-route-results--tab-active {
  display: block;
}

.c-route-results__headings {
  padding: s.$size--500 s.$panel-item-padding--desktop 0 s.$panel-item-padding--desktop;
}

.c-route-results__list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid s.$color--gray-lines;
}

.c-route-result {
  position: relative;
  padding: s.$panel-item-padding--mobile;
  border-bottom: 1px solid s.$color--gray-lines;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    height: calc(100% - 8px);
    border-left: 4px solid s.$color--success;
    opacity: 0;
    transform: translateX(-4px);
    transition: transform s.$speed--default s.$easing--default, opacity s.$speed--default s.$easing--default;
  }

  @include breakpoints.tablet-portrait-up {
    padding: s.$size--400 s.$panel-item-padding--desktop;
  }
}

.c-route-result--selected {
  &:before {
    transform: translateX(0);
    opacity: 1;
  }

  .c-route-result__name {
    color: s.$color--emperor;
    text-decoration: none;
    cursor: default;
  }
}

.c-route-result__heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: s.$size--100;
}

.c-route-result__name,
.c-route-result__cur-duration {
  font-size: s.$font-size--300;
  font-weight: s.$weight--semi;
}

.c-route-result__name {
  flex: 1 1 80%;
  margin-right: s.$size--200;
}

.c-route-result__cur-duration {
  flex: 1 0 auto;
  text-align: right;
}

.c-route-result__comparative-time,
.c-route-result__action {
  font-size: s.$font-size--100;
  background: transparent;
  border:none;
  display: inline-flex;
  align-items: center;
}

.c-route-result__action{
  color: s.$color--regal-blue;
  text-decoration:underline;
  &:hover{
    color: s.$color--jellybean;
    cursor: pointer;
  }
}

.c-route-result__comparative-time,
.c-route-result__actions {
  margin-top: s.$size--200;
}

.c-route-result__action {
  margin-right: s.$size--400;
}

.c-route-results__list--mobile-only .c-route-result--selected {
  &::before {
    display: none;
  }
  @include breakpoints.tablet-portrait-up {
    display: none;
  }
}
