.mfa-app {
  min-width: auto;
}

.mfa-action-list {

  &__item {
    list-style: none;
    margin: 0;
  }

  .btn-primary {
    min-width: 35%;
    background: #236FA6;
    color: #fff;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    padding: 12px;
    margin-bottom: 1rem;
    text-align: center;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-text: #555;
    --color-dark-text: #555;
    --color-loading-indicator: $ms-blue;
    --color-tile-border: $ms-blue;
    --color-tile-border-hover: $ms-blue;
    --color-tile-border-active: $ms-blue-extra-dark;
    --tile-box-shadow-active: 0 0 0 2px $ms-blue-extra-dark inset;
    --tile-mask-background: rgba(0,0,0,.75);
  }
}
