@use '../../0-settings/settings.scss' as s;

.c-hotspot-keys {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.c-hotspot-btn-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
}

.c-hotspot-btn-group__btn {
  box-shadow: none;
  text-decoration: underline;
  display: block;
  transition: color s.$speed--default s.$easing--default;
  padding: 0;
  margin-right: 24px;

  &:hover {
    color: s.$color--primary;
    box-shadow: none;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 4px;
    height: 2px;
    width: 100%;
    background-color: s.$color--success;
    opacity: 0;
    transform: translateY(-4px);
    transition: transform s.$speed--default s.$easing--default, opacity s.$speed--default s.$easing--default;
  }
}

.c-hotspot-btn-group__btn--active {
  text-decoration: none;
  color: s.$color--emperor;

  &:hover {
    color: s.$color--emperor;
    box-shadow: none;
  }

  &:after {
    transform: translateY(0);
    opacity: 1;
  }
}

.c-hotspot-panel__direction-heading {
  border-bottom: 2px solid s.$color--silver-chalice;
  padding-bottom: 8px;
  margin-bottom: 4px;
  width: 100%;
  font-weight: s.$weight--semi;
}

.c-hotspot-panel__direction-group {
  margin-bottom: 32px;
}

.c-hotspot-panel__table {
  border: none;
}

.c-hotspot-panel__row {
  border-bottom: 1px solid s.$color--gray-wash;
}

.c-hotspot-panel__cell {
  padding: 8px 0 16px 0;
  border-right: none;
}

.c-hotspot-panel__footer-text {
  p {
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: s.$font-size--200;
  }
}

.hotspot-key-colour {
  padding: 0px 5px;
}
.hotspot-key-colour.busy {
  border-left: solid 20px #ffa100;
}
.hotspot-key-colour.heaviest {
  border-left: solid 20px #cc2828;
}

.hotspot-key-colour.free-flowing {
  border-left: solid 20px #afc83d;
}

.hotspot-heaviest {
  display: block;
  font-weight: bold;
}
.hotspot-direction {
  color: #236fa6;
  padding-top: 15px;
}

.hotspot-chart {
  margin-bottom: 20px;
}

.hotspot-row {
  height: 20px;
  width: 100%;
}
.hotspots-date {
  width: 40px;
  padding: 5px;
}
.hotspots-box-wrap {
  height: 100%;
  position: relative;
}
.hotspots-box {
  position: absolute;
  top: 0;
  bottom: 0;
}
.sep {
  border-left: 1px solid #000;
}
.hotspot-listing table,
.hotspot-listing td {
  border: none;
}

rect.normal {
  fill: url('#diagonal-stripe-1');
}
rect.busy {
  fill: s.$color--tulip-tree;
}
rect.heaviest {
  fill: s.$color--well-read;
}
.tick line {
  fill: none;
  stroke: rgba(0, 0, 0, 0.1);
  shape-rendering: crispEdges;
  pointer-events: none;
}
.tick line.highlight {
  stroke: rgba(0, 0, 0, 0.25);
}
