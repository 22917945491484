/*
 * Global shadow scale
 *
 * Non-linear scale to use for spacing and sizing throughout project. Based on scale suggested in Refactoring UI book (pg 63)
 */

@use './colors' as c;

$shadow--100: 0px -2px 4px 0px rgba(c.$color--black, 0.25);
$shadow--200: 0px 4px 4px 0px rgba(c.$color--black, 0.25);
