@use '../0-settings/settings' as s;
@use '../1-mixins/breakpoints';

.l-traffic-table {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    // max-width: 960px;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}

.l-traffic-table__filters {
  display: flex;
  flex-wrap: wrap;
}

.l-traffic-table__filters .c-form-field {
  flex: 1 1 100%;
  @include breakpoints.ipad-portrait-up {
    flex: 1 0 auto;
    max-width: 256px;
    &:not(:last-child) {
      margin-right: s.$size--500;
    }
  }
}

.l-traffic-table .c-text-input {
  max-width: none;
}

.l-traffic-table__wrapper {
  overflow: auto;
  padding: s.$size--500;
  flex: 1;
  height: 100%;
  @include breakpoints.ipad-portrait-up {
    padding: s.$size--650;
    margin-bottom: 0;
  }
}

.l-traffic-table__heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: s.$size--500;
}

.l-traffic-table__heading caption {
  margin-right: s.$size--600;
  margin-bottom: 0;
  flex: 1 1 auto;
  font-size: s.$font-size--600;
  font-weight: s.$weight--semi;
  text-align: left;
  @include breakpoints.ipad-portrait-up {
    font-size: s.$font-size--700;
  }
}

.l-traffic-table__heading p {
  margin: 0;
  line-height: s.$size--500;
  flex: 0 1 auto;
}

.l-traffic-table__column-headers thead {
  @media only screen and (max-width: 37.49em) {
    display:none;
  }
}
.l-traffic-table__column-headers th {
  background-color: #eee;
}
