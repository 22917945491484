/**
 * Standard border-radius values to use throughout application.
 * Should consist of default, smaller/larger values as required.
 */

$border-radius--default: 3px;
$border-radius--medium: 4px;
$border-radius--large: 12px;
$border-radius--x-large: 15px;

/**
* Common global aliases
*/

// Use on square ratios only (to create circle)
$border-radius--circle: 100%;
