@use '../0-settings/settings.scss' as s;
@use '../1-mixins/breakpoints';

.MyJourneysPage{
  .l-container{
      margin-top:24px;
  }

  .margin-btm-40{
    margin-bottom: 40px;
  }

  #deleteroute, #deleteregion{
    color: s.$color--error;
    margin-top: 32px;
    @include breakpoints.tablet-landscape-up {
      margin-top: unset;
    }
  }

  #map{
    margin-bottom: 32px;
  }

  .no-routes, .no-regions{
    display:flex;
    align-items: center;
    border-top: 1px solid s.$color--silver-chalice;
    border-bottom: 1px solid s.$color--silver-chalice;
    padding-top:40px;
    padding-bottom: 40px;
    flex-direction: column;
    a{
      margin-top: 24px;
      display:flex;
      align-items: center;
      svg{ 
        margin-right: 5px;
      }
    }
  }

  .alert-period{
    display:flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidebar__title{
    border-bottom: s.$color--silver-chalice 1px solid;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .border-bottom{
    border-bottom: 1px solid s.$color--silver-chalice;
  }

  #commute-finder{
    margin-top: 40px;
    margin-bottom: 40px;

  }

  .route-days-of-week{
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    span{
      width: 50%;
      margin-bottom: 16px;
    }
    @include breakpoints.tablet-landscape-up{
      span{
        width: unset;
      }
    }
  }

  .c-alerttime{
    display:flex;

  }
  
  .c-alerttime__label{
    font-weight: 600;
    margin-right: 24px;
    width: 128px;
  }

  .route-details-subform{
    @include breakpoints.tablet-landscape-up {
      max-width:80%;
    }
  }
  
  .btn-toolbar--alt{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    margin-bottom: 80px;
    border-top: 1px solid s.$color--silver-chalice;
    @include breakpoints.phone-only {
      margin-bottom: 32px;
      > div{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .c-btn--font-light{
          margin-right: unset;
          width: 48%;
        }
      }
    }
    @include breakpoints.tablet-landscape-up {
      flex-direction: row
    }
  }

  #commute-finder .c-form-field:last-child{
    margin-bottom: 0;
  }

  .link-list{
    &__anchor{
      justify-content: space-between;

      .c-side-menu__link-icon{
        width: 16px;
        height: 16px;
      }
    }
    a:first-child{
      padding-bottom: 10px; 
    }
    a:hover{
      text-decoration:underline;
      cursor: pointer;
    }
  }
}


.modal{
  &__bg{
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left:0;
      background-color: rgba(243,244,250,.9);
      display:flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
  }
  &__container{
      position: relative;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 30px -5px rgb(68 51 122 / 30%);
      border-radius: 3px;
      padding: 32px;
      background-color: #fff;
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      h3{
          font-size: 20px;
      }
      p{
          font-size: 16px;
      }
      @include breakpoints.tablet-landscape-up {
          height: auto;
          max-width: 640px;
          h3{
              font-size: 16px;
              margin-top:0;
          }
          p{
              font-size: 14px;
          }

      }
      
      .c-modal__close-button > button{
          background-color: transparent;
          border: 0;
          .c-icon{
             width: 28px;
             height: 28px;    
             &:hover{
              cursor: pointer;
             } 
          }
      }

  }
  &__actions{
      display:flex;
      justify-content: space-between;
      @include breakpoints.tablet-landscape-up {
          justify-content: flex-start;
      }
  }
}
  