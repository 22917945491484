.no-js {
  //   font-size: 100%;
  .legacy-styles {
    font-size: initial;
    display: block;
    @import '../lib-sass/screen.scss';
    @import 'colours.scss';
    @import 'helpers.scss';
    @import 'icons.scss';
    @import 'forms.scss';
    @import 'components.scss';
    @import 'holidayhotspots.scss';
    @import 'mfa.scss';
    @import 'misc.scss';
  }
}
