@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.c-key {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.c-key__key {
  display: block;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  margin-right: 8px;
}

.c-key__key--red {
  background: s.$color--error;
}
.c-key__key--orange {
  background: s.$color--warning;
}
.c-key__key--green {
  background: s.$color--success;
}

.c-key__key--light-green {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCI+CiAgICA8cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiLz4KICAgIDxwYXRoIGQ9Ik0tMSwxIGwyLC0yICAgICAgICAgICAgTTAsMTAgbDEwLC0xMCAgICAgICAgICAgIE05LDExIGwyLC0yIiBzdHJva2U9IiNiNGM2NTYiIHN0cm9rZS13aWR0aD0iNSIvPgo8L3N2Zz4=');
  background-repeat: repeat;
}

.c-key__key--square {
  border-radius: 0;
}

.c-key__key--triangle {
  background: none;
  border-radius: 0px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid s.$color--error;
}

.c-key__label--square {
  font-weight: s.$weight--semi;
}

.c-delay-keys {
  display: flex;
  margin-top: 8px;
}
