@use '../../0-settings/settings' as s;
@use '../../1-mixins/breakpoints';

.radio{
    padding-bottom: 20px;
    display:flex;
    color: s.$color--jellybean;
    .field__label{
      font-size: 12px;
      font-weight: 600;
      color: s.$color--emperor;
    }
    &:hover{
      cursor: pointer
    }
  
    .radio-btn-label {
      font-size: 14px;
      margin-left: 10px;
      &:hover{
        cursor: pointer
      }
    }
    input {
      position: relative;
      &:hover{
        cursor: pointer
      }
    } 
    input:before {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      top: 0px;
      left: 0px;
      position: relative;
      border: 2px solid  s.$color--jellybean;
      background-color: #fff;
      content: '';
      display: inline-block;
      visibility: visible;
    }
  
    input:checked:after{
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      top: 4px;
      left: 4px;
      content: '';
      background-color: s.$color--jellybean;
      display: inline-block;
      visibility: visible;
    }
  
    input:checked ~ .radio-btn-label{
      font-weight: 600;
    } 
}

.region-select{
    justify-content: space-between;
    flex-direction: column;
    @include breakpoints.tablet-landscape-up {
      flex-direction: row;
  }
}

.radio--region{
    padding-bottom: 20px;
    &:hover{
        cursor: pointer
    }
    .radio-btn-label:hover{
        cursor: pointer
    }
}
.radio--region-select {
    flex-direction: column; 
    .field__label{
        padding-bottom: 10px;
    }
}