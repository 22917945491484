$hr-colour: #ccc;

/*------------------------------------*\
    #TABLES
\*------------------------------------*/

table {
    //todo @extend %block-default-spacing;
    border: 1px solid $hr-colour;
    // font-size: 14px;
    // font-size: .8125rem;
    //todo @include font-size(14);
    width: 100%;
    //    @include media(small-only) {
    //        font-size: 10px;
    //        font-size: .625rem;
    //    }

    @extend %mb-xxl;
    text-align: left;


    > thead {
        background-color: $lighter-grey;
        border-color: $hr-colour;
    }

    caption {
        text-align: left;
        padding: 4px 0;
    }

    thead, tfoot {
        font-weight: 700;
    }

    td, th {
        padding: .75em;
        border-right: solid 1px $hr-colour;
        vertical-align: top;
        word-wrap: break-word;
    }

    &.table--header-borders thead th {
        border: 1px solid #ddd;
    }

    &.table--zebra {
        & > thead {
            border-bottom: solid 1px $hr-colour;
        }
        & > tfoot {
            border-top: solid 1px $hr-colour;
        }
        & > tbody > tr:nth-child(odd) {
            td, th {
                background-color: #fafafa;
            }
        }
    }

    &.table--plain {
        border: 0;
        & td,
        & th {
            padding: 0 0 .5em 0;
            border-right: 0;
        }
        & td:first-child,
        & th:first-child {
            padding-left: 0;
        }
    }

    &.table--responsive {
        @include media(small-down) {
            display: block;
            border: none;


            td, tr, tbody, th {
                display: block;
                width: 100%;
                text-align: left;
                border-right: none;
                margin-top: 0;
            }
            thead {
                display: none;
            }
            td:first-child, th {

                padding: 0;
                margin-top: $base-spacing-unit;
                font-weight: bold; // ideally this would extend the h5

            }
            th {
                font-weight: bold;
            }

            // Forgive me for this.
            tbody tr:nth-child(odd) td,
            tbody tr:nth-child(odd) th {
                background: transparent;
            }

            tbody tr:nth-child(odd) td + td,
            tbody tr:nth-child(odd) th + td,
            td + td,
            th + td {
                background: #F7F7F7;
            }

            td + td {
                border-top: solid 1px #e6e6e6;
            }
            td[data-header] {
                overflow: hidden;
                display: block;
                position: relative;
                padding-left: 50%;
            }
            td[data-header]:before {
                content: attr(data-header);
                float: left;
                width: 95%;
                margin-left: -100%;
                color: #777;
            }
        }
    }

    &.table--small-text--q-mobile {
        @include media(small-down) {
            //todo @include font-size(11);
        }
    }

}

